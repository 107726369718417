import { Button, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { changerNiveau } from 'src/service/AgentService';

const ModalEditNiveau = (props) => {
  const [niveau, setNiveau] = useState();
  const onChange = (e) => {
    setNiveau(e.target.value);
  };
  useEffect(() => {
    if (props.data.niveau !== null) setNiveau(props.data.niveau);
  }, [props.data]);

  const handleSubmit = async () => {
    await changerNiveau(props.data.id, niveau).then((response) => {
      if (response.status === 200) {
        // window.location = "/agent/profiles?niveau";
        window.location.reload();
      }
    });
  };
  return (
    <>
      <Modal centered isOpen={props.open} toggle={props.handleClose} className="modal-dialog-scrollable">
        <ModalHeader toggle={props.handleClose}>
          Changer le niveau de {props.data.nom + ' ' + props.data.prenom}
        </ModalHeader>
        <ModalBody>
          <TextField
            type="text"
            required
            label="Niveau"
            id="niveau"
            name="niveau"
            value={niveau}
            fullWidth
            variant="outlined"
            onChange={onChange}
            select
          >
            <MenuItem value={0}> Simple utilisateur </MenuItem>
            <MenuItem value={1}> Modérateur</MenuItem>
            <MenuItem value={2}> Administrateur</MenuItem>
          </TextField>
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" onClick={handleSubmit}>
            Valider
          </Button>
          <Button variant="outlined" onClick={props.handleClose}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalEditNiveau;
