import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";

const axiosInstance = axios.create({
    baseURL: siteUrl("chats")
});

export function getChatByIdDemande(idDemande) {
    return axiosInstance.request({
        method: "GET",
        url: "/" + idDemande
    });
}