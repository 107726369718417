import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import ActionButton from '../ActionButton';
import ModalDetailPapier from './ModalDetailPapier';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from '../hook-form';

const ListePapierAdmin = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const changeData = (newData) => {
    setData(newData);
  };

  const columns = [
    { id: 'nom', label: 'Désignation', minWidth: 170, hidden: false },
    { id: 'typePapier', label: 'Type', minWidth: 170, hidden: false },
    { id: 'unite', label: 'Unité', minWidth: 80, hidden: false },
    {
      id: 'tarifEspece',
      label: 'Tarif (Ar)',
      minWidth: 80,
      align: 'right',
      format: (value) => value.toLocaleString('fr-FR'),
      hidden: false,
    },
    {
      id: 'compta',
      label: 'Compta',
      minWidth: 170,
      align: 'right',
      hidden: true,
    },
    {
      id: 'action',
      label: 'Action',
      minWidth: 170,
      align: 'center',
      //   hidden: props.rank === 1 ? false : true,
      hidden: Boolean(props.rank),
    },
  ];
  /*recherche avance*/
  const defaultSearch = {
    nom: '',
    type: 0,
    prixMin: null,
    prixMax: null,
  };

  const type = [
    { id: 0, nom: 'tous' },
    { id: 1, nom: 'Acte de vente' },
    { id: 2, nom: '	Autorisation' },
  ];

  const methods = useForm({ defaultSearch });
  /*on submit*/
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    console.log('submited');
    console.log(data);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box my={1}>
        {/* <ModalPapierAdmin data={data} open={open} handleClose={handleClose} /> */}
        <ModalDetailPapier data={data} open={open} handleClose={handleClose} />
        <Typography variant="h3">{props.titre}</Typography>

        {/*recherche avancee */}
        <Box mb={1}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item lg={3} xs={6}>
                <RHFTextField name="nom" size="small" label="Désignation" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={3} xs={6}>
                <RHFTextField name="type" size="small" select label="Type" variant="outlined" fullWidth>
                  {type.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {' '}
                      {item.nom}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
              <Grid item lg={2} xs={6}>
                <RHFTextField name="prixMin" size="small" label="Prix Min" variant="outlined" type="number" fullWidth />
              </Grid>
              <Grid item lg={2} xs={6}>
                <RHFTextField name="prixMax" size="small" label="Prix Max" variant="outlined" type="number" fullWidth />
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit" size="medium">
                  RECHERCHER
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
        {/*table */}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns
                  .filter((column) => column.hidden === false)
                  .map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns
                      .filter((column) => column.hidden === false)
                      .map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                            {column.id === 'action' ? (
                              <ActionButton handleData={() => changeData(row)} handleOpen={handleOpen} data={row} />
                            ) : null}
                          </TableCell>
                        );
                      })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default ListePapierAdmin;
