import {
  Avatar,
  Button,
  Card,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { siteUrl } from 'src/utils/baseUrl';
import ModalDetailAgent from './ModalDetailAgent';
import ModalHandleAgent from './ModalHandleAgent';

const TableAgent = (props) => {
  /*modal detail handler*/
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /*modal creation and modification handler*/
  const [modalType, setModalType] = useState('create');
  const toCreate = () => {
    setModalType('create');
  };
  const toEdit = () => setModalType('edit');

  const [openAjout, setOpenAjout] = useState(false);
  const handleOpenAjout = () => setOpenAjout(true);
  const handleCloseAjout = () => setOpenAjout(false);

  let now = new Date();
  const defaultValue = {
    idCommune: 1,
    nom: '',
    prenom: '',
    fonction: '',
    dateNaissance: now,
    lieuNaissance: '',
    adresse: '',
    cin: '',
    dateCin: now,
    lieuCin: '',
    telephone: '',
    email: '',
    salaire: 0,
    sexe: 'm',
  };
  const [data, setData] = useState(defaultValue);

  const changeData = (newData) => {
    setData(newData);
  };

  // const initData = () => setData(defaultValue);
  return (
    <>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3">Liste des Agents</Typography>
          <Button
            onClick={() => {
              toCreate();
              handleOpenAjout();
            }}
          >
            {' '}
            Nouveau Agent
          </Button>
        </Stack>
        <Card>
          <ModalHandleAgent
            modalType={modalType}
            initData={defaultValue}
            open={openAjout}
            data={data}
            handleClose={handleCloseAjout}
          />
          <ModalDetailAgent open={open} data={data} handleClose={handleClose} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">Nom</TableCell>
                  <TableCell align="left">Fonction</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="right">
                      {row.photo !== null ? <Avatar src={siteUrl('files/agent/' + row.photo)} /> : <Avatar />}
                    </TableCell>
                    <TableCell align="left">{row.nom + ' ' + row.prenom}</TableCell>
                    <TableCell align="left">{row.fonction}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          changeData(row);
                          handleOpen();
                        }}
                      >
                        Voir
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setData(row);
                          toEdit();
                          handleOpenAjout();
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </>
  );
};

export default TableAgent;
