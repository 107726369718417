
import UploadForm from "src/components/file/UploadForm";


const TestPage = () => {

    return (
        <>
            <h1>test</h1>
            <UploadForm />


        </>
    );
}

export default TestPage;