import { Avatar, Grid } from "@mui/material";
import { getNiveau } from "src/service/AgentService";
import { siteUrl } from "src/utils/baseUrl";
import { formatMoney } from "src/utils/formatNumber";
import { fDateFr } from "src/utils/formatTime";
import AgentStatus from "./AgentStatus";

const DetailAgent = ({ data, pro }) => {

    if ({ data }) {
        return (
            <>
                {
                    pro ? (
                        <Grid container>
                            <Grid item lg={5} md={5} xs={12}>
                                <Avatar sx={{ width: 200, height: 200 }} src={siteUrl("files/agent/" + data.photo)} />
                            </Grid>
                            <Grid item lg={7} md={7} xs={12}>
                                <Grid container>
                                    <Grid item lg={4} md={4} xs={12}><strong>Status:</strong></Grid> <Grid item lg={8} md={8} xs={12}><AgentStatus active={data.activeProfil} /></Grid>
                                    <Grid item lg={4} md={4} xs={12}><strong>Niveau:</strong></Grid> <Grid item lg={8} md={8} xs={12}>{getNiveau(data.niveau)}</Grid>
                                    <Grid item lg={4} md={4} xs={12}><strong>Email Pro:</strong></Grid> <Grid item lg={8} md={8} xs={12}>{data.emailPro}</Grid>
                                    <Grid item lg={4} md={4} xs={12}><strong>Tel Pro:</strong></Grid> <Grid item lg={8} md={8} xs={12}>{data.telPro}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>) :
                        <center><Avatar sx={{ width: 200, height: 200 }} src={siteUrl("files/agent/" + data.photo)} /></center>
                }



                <Grid container spacing={2} >
                    <Grid item lg={4} md={4} xs={12}><strong>Nom: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.nom} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Prénom: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.prenom} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Fonction: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.fonction} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Date de naissance: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{fDateFr(data.dateNaissance)} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Lieu de naissance: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.lieuNaissance} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Adresse: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.adresse} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Numero CIN: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.cin} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Date CIN: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{fDateFr(data.dateCin)} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Lieux CIN: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.lieuCin} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Téléphone: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.telephone} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Email: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.email} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Salaire: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{formatMoney(data.salaire)} </Grid>
                    <Grid item lg={4} md={4} xs={12}><strong>Sexe: </strong> </Grid> <Grid item lg={8} md={8} xs={12}>{data.sexe === 'm' ? 'homme' : 'femme'} </Grid>
                </Grid>

            </>
        );
    }

}

export default DetailAgent;