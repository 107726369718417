import { Button } from '@mui/material';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { disableProfil } from 'src/service/AgentService';

const ModalDisableCompte = (props) => {
  const handleSubmit = async () => {
    await disableProfil(props.data.id).then((response) => {
      if (response.status === 200) {
        // window.location = "/agent/profiles?disable";
        window.location.reload();
      }
    });
  };
  return (
    <>
      <Modal centered isOpen={props.open} toggle={props.handleClose} className="modal-dialog-scrollable">
        <ModalHeader toggle={props.handleClose}>Confirmation de désactivation</ModalHeader>
        <ModalBody>
          Êtes-vous sur de désactiver le compte de <strong>{props.data.nom + ' ' + props.data.prenom}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" color="error" onClick={handleSubmit}>
            Valider
          </Button>
          <Button variant="outlined" onClick={props.handleClose}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalDisableCompte;
