import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";

const axiosInstance = axios.create({
    baseURL: siteUrl("paiements")
});
export const choixPaiement = async (idDemande, modePaiement) => {
    const url = siteUrl("paiements/choices");
    try {
        return await axios.put(url, null, {
            params: {id: idDemande, mode: modePaiement}
        });
    } catch (error) {
        console.error(error);
    }
};

export function getUnpaids() {
    return axiosInstance.request({
        method: "GET",
        url: "/unpaids"
    });
}


