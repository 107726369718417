import axios from 'axios';
import { siteUrl } from 'src/utils/baseUrl';

const axiosInstance = axios.create({
  baseURL: siteUrl("citoyens")
});

export const ajoutCitoyen = (newCitoyen) => {
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios.post(siteUrl('citoyens'), JSON.stringify(newCitoyen), customConfig);
};

// export function getCitoyen(pageTarget, dataBody) {
//   return axiosInstance.request({
//       method: "GET",
//       params: {page: pageTarget, size:20},
//       data: JSON.stringify(dataBody),
//       headers: {
//         'Content-Type': 'application/json',
//       },
//   });
// }

export function getCitoyen(pageTarget, dataBody) {
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {page: pageTarget, size:20},
  };
  console.log(dataBody);
  return axios.post(siteUrl('citoyens/search'),  JSON.stringify(dataBody), customConfig);

}
export function getStatPopulation(dataBody) {
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  console.log(dataBody);
  return axios.post(siteUrl('citoyens/stats'),  JSON.stringify(dataBody), customConfig);

}