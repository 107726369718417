import React from 'react';
import FormAjoutCitoyen from 'src/components/population/FormAjoutCitoyen';
import Page from 'src/components/Page';
import { Typography } from '@mui/material';

export default function AjoutCitoyen() {
  return (
    <Page title="ajout citoyen">
      <Typography variant="h3" marginBottom={1}>
        Nouveau citoyen
      </Typography>
      <FormAjoutCitoyen />
    </Page>
  );
}
