import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormGroup, Grid, Radio } from '@mui/material';

/*necessite 
* name
* options = [{value='', label=''},{value='', label=''}];
* direction: optional (row (default)/ column)
*/

RHFRadioButton.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
};


export function RHFRadioButton({ name, options, direction="row",...other }) {
  const { control } = useFormContext();
return (
  <Controller
    name={name}
    control={control}
    render={({ field }) => {
      const onSelected = (option) =>
        field.value === option ? '' : option;

      return (
        <FormGroup>
          <Grid container direction={direction} spacing={2}>
          {options.map((option) => (
            <Grid item key={option.value}>
            <FormControlLabel
              key={option.value}
              control={
                <Radio
                  checked={field.value === option.value}
                  onChange={() => field.onChange(onSelected(option.value))}
                  value={option.value}
                />
              }
              label={option.label}
              {...other}
            />
            </Grid>
          ))}
          </Grid>
        </FormGroup>
      );
    }}
  />
);
}


