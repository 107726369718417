import { Check, CheckCircleOutline, Close } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { getDemandeById } from "src/service/DemandePapierService";
import { useAuth } from "src/utils/auth";
import { siteUrl } from "src/utils/baseUrl";
import { fNumber } from "src/utils/formatNumber";
import { fDateFr } from "src/utils/formatTime";
import { AgentLoaderMini } from "../AgentLoader";
import StatusDemande from "../StatusDemande";
import ModalRdv from "./ModalRdv";

const DetailDemandeBox = (props) => {
    const auth = useAuth();
    const agent = auth.getAgent();
    const [load, setLoad] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [demande, setDemande] = useState([]);
    useEffect(() => {
        getDemandeById(props.idDemande).then((response) => {
            console.log(response.data.items);
            setDemande(response.data.items);
            setLoad(false);
        });

    }, []);

    let urlValider = siteUrl("demandes-papiers/valider/" + demande.id);
    let urlIncomplet = siteUrl("demandes-papiers/incomplet/" + demande.id);
    const validation = async () => {
        try {

            return axios.put(urlValider, null).then((response) => { if (response.status === 200) window.location.reload() });
            // setResponse(res);
        } catch (error) {
            console.error(error);
        }
    };
    const incomplet = async () => {
        try {

            return axios.put(urlIncomplet, null).then((response) => { if (response.status === 200) window.location.reload() });
            // setResponse(res);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        (load === true ? <AgentLoaderMini /> : (
            <>
                <ModalRdv
                    idDemande={demande.id}
                    demande={demande.papierAdmin}
                    open={open} handleClose={handleClose} />
                <Typography variant="h4" align="center" mb={1}>
                    Info de  la demande
                </Typography>
                <Grid container>
                    <Grid item xs={4} lg={4}>
                        <strong>ID:</strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {demande.id}
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <strong>Demande:</strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {demande.papierAdmin}
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <strong>Type: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {demande.typePapier}
                    </Grid>

                    <Grid item xs={4} lg={4}>
                        <strong>Date: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {fDateFr(demande.dateHeure)}
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <strong>Nombre: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {demande.examplaire}
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <strong>Ref paiement: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {demande.ref}
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <strong>Montant: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {fNumber(demande.prixAutre * demande.examplaire) + " Ar"}
                    </Grid>

                    <Grid item xs={4} lg={4}>
                        <strong>Status: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        <StatusDemande idStatus={demande.etat} />
                    </Grid>
                    <Grid item xs={4} lg={4}>
                        <strong>RDV: </strong>
                    </Grid>
                    <Grid item xs={8} lg={8}>
                        {demande.rdv}
                    </Grid>
                    {/* <Grid item xs={12} lg={12}>
                    <strong>Description: </strong>
                </Grid>
                <Grid item xs={12} lg={12}>
                    {demande.info != null && parse((demande.info))}
                </Grid> */}
                </Grid>


                {
                    agent !== null &&
                    (<>
                        {
                            (demande.etat < 6 &&
                                (demande.etat === 5 ?
                                    <>
                                        <Button color="success" className="float-end mt-2" onClick={handleOpen} >Finie <CheckCircleOutline /> </Button>
                                    </>
                                    :
                                    <>
                                        <Button color="danger" className="float-end mt-2" onClick={incomplet} >Incomplète <Close /> </Button>
                                        <Button color="primary" className="float-end mt-2 me-2" onClick={validation} >Valider <Check /> </Button>
                                    </>)
                            )
                        }



                    </>)
                    // : (demande.etat < 5 &&
                    //     <Button color="info" className="float-end mt-2" onClick={() => { props.changeData(demande); props.handleClick() }} >Modifier <Edit /> </Button>)
                }

            </>
        ))

    );
}

export default DetailDemandeBox;