import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from 'src/utils/auth';
// @mui
import { Link, Stack, IconButton, InputAdornment, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { useEffect } from 'react';
import { login } from 'src/service/AgentService';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [dataSession, setDataSession] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email invalide').required('Entrez votre email'),
    password: Yup.string().required('Entez votre mot de passe'),
  });

  const defaultValues = {
    email: 'rakoto@gmail.com',
    mdp: '1111',
  };

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const onSubmit = (data) => {
    auth.logoutCitoyen();
    // console.log("submit")
    console.log("submit");
    login(data).then(response=>{
      // setDataSession();
      auth.loginAgent({
        idAgent: response.data.profile.id,
        email:response.data.profile.email, 
        niveau: response.data.profile.niveau
      });
      navigate('/agent/dashboard-papier', { replace: true });
    }).catch(error => {
      console.error(error);
    })
    
    // Session.set('agent', data)

    

  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email" />
        <RHFTextField
          name="mdp"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}

          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Mot de passe oublié?
        </Link>
      </Stack>

      <Button fullWidth size="large" type="submit" variant="contained" >
        Login
      </Button>
    </FormProvider>
  );
}
