import { Alert, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from "axios";
import 'dayjs/locale/fr';
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { siteUrl } from "src/utils/baseUrl";
import { fDateTimeJava } from "src/utils/formatTime";


const ModalRdv = (props) => {

    let now = new Date();
    now.setHours(8);
    now.setMinutes(0);
    now.setSeconds(0);
    const [dateRef, setDateRef] = useState(now);
    const [room, setRoom] = useState('');

    const [error, setError] = useState(null);

    let url = siteUrl('demandes-papiers/' + props.idDemande + "/rdv");
    const onSubmit = () => {
        fixerRdv()
    }


    const fixerRdv = () => {
        return axios.put(url, null, {
            params: {
                id: props.idDemande,
                time: fDateTimeJava(dateRef),
                room: room
            },
        }).then((response) => {
            setError(null);
            window.location.reload()
        }).catch((error) => {
            setError(error.response.data.message);
            console.error(error);
        });
    };

    return (
        <>
            <Modal
                centered
                isOpen={props.open}
                toggle={props.handleClose} >
                <ModalHeader toggle={props.handleClose}>
                    <Typography variant="h5" >
                        {`${props.idDemande} |  ${props.demande} `}
                    </Typography>
                </ModalHeader>
                <ModalBody>
                    <Stack spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
                            <DatePicker
                                value={dateRef}
                                onChange={(newValue) => {
                                    setDateRef(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                label="Date"
                            />
                            <TimePicker
                                value={dateRef}
                                onChange={(newValue) => {
                                    setDateRef(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                label="Heure"
                            />
                        </LocalizationProvider>
                        <TextField
                            id="salle"
                            type="text"
                            value={room} required
                            onChange={(e) => { setRoom(e.target.value) }}
                            fullWidth
                            label="Salle" />
                        {error !== null && (
                            <Alert variant="filled" severity="error" >{error}</Alert>
                        )}

                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onSubmit}>Valider</Button>{' '}
                    <Button color="secondary" onClick={props.handleClose}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalRdv;