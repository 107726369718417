import { lazy } from "react";
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Agent from "./pages/Agent";
import AjoutCitoyen from './pages/AjoutCitoyen';
import AjoutPapier from './pages/AjoutPapier';
import Blog from './pages/Blog';
import CompteAgent from "./pages/CompteAgent";
import CreateForm from "./pages/CreateForm";
import DashboardApp from './pages/DashboardApp';
import DashboardPapier from "./pages/DashboardPapier";
import DemandeAdmin from "./pages/DemandeAdmin";
import DetailCitoyen from './pages/DetailCitoyen';
import DetailCompteAgent from "./pages/DetailCompteAgent";
import DetailDemandeAdmin from "./pages/DetailDemandeAdmin";
import GraphPop from "./pages/GraphPop";
import Login from './pages/Login';
import ModifPapier from './pages/ModifPapier';
import NotFound from './pages/Page404';
import PaiementInvalide from "./pages/PaiementInvalide";
import PapierAdmin from './pages/PapierAdmin';
import Population from './pages/Population';
import Products from './pages/Products';
import Register from './pages/Register';
import Test from "./pages/Test";
import User from './pages/User';
import RequireAuth, { RequireAuthCitoyen } from './utils/requireAuth';
import TestPage from "./views/client-page/TestPage";



// ----------------------------------------------------------------------
/****Layouts*****/
const FullLayout = lazy(() => import("./layouts/FullLayout.js"));

/***** Pages ****/

const PapiersClient = lazy(() => import("./views/client-page/PapiersClient"));
const DemandeClient = lazy(() => import("./views/client-page/DemandeClient"));
const DetailDemande = lazy(() => import("./views/client-page/DetailDemande"));
const AjoutDemande = lazy(() => import("./views/client-page/AjoutDemande"));
const LoginClient = lazy(() => import("./views/client-page/LoginClient"));
const SignupClient = lazy(() => import("./views/client-page/SignupClient"));
const UploadPage = lazy(() => import("./views/client-page/UploadPage"));
const Payement = lazy(() => import("./views/client-page/Payement"));
export default function Router() {
  return useRoutes([
    {
      path: '/agent/dashboard',
      element: <RequireAuth><DashboardLayout /></RequireAuth>,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: '/agent',
      element: <RequireAuth><DashboardLayout /></RequireAuth>,
      children: [
        // { path: 'population', element: <Population /> },
        { path: 'dashboard-papier', element: <DashboardPapier /> },
        { path: 'ajout-citoyen', element: <AjoutCitoyen /> },
        { path: 'population', element: <Population /> },
        { path: 'population/:graphs', element: <GraphPop /> },
        { path: 'detail-citoyen', element: <DetailCitoyen /> },
        { path: 'ajout-papier', element: <AjoutPapier /> },
        { path: 'ajout-papier/cree-form', element: <CreateForm /> },
        { path: 'papier-admins', element: <PapierAdmin /> },
        { path: 'modif-papier', element: <ModifPapier /> },
        { path: 'paiement-invalide', element: <PaiementInvalide /> },
        { path: 'demandes', element: <DemandeAdmin /> },
        { path: 'demandes/:id', element: <DetailDemandeAdmin /> },
        { path: 'liste', element: <Agent /> },
        { path: 'profiles', element: <CompteAgent /> },
        { path: 'profiles/:id', element: <DetailCompteAgent /> },
        { path: "test", element: <Test /> }
      ],
    },
    // {
    //   path: '/client',
    //   element: <ClientLayout />,
    //   children: [
    //     { path: 'papiers', element: <ListePapierAdminClient /> },
    //     { path: 'demandes', element: <ListeDemandeClient /> },
    //   ],
    // },
    {
      path: "/client",
      element: <FullLayout />,
      children: [
        // { path: "/", element: <Navigate to="/client/demandes" /> },
        { path: "papiers", exact: true, element: <PapiersClient /> },
        { path: "demandes", element: <RequireAuthCitoyen><DemandeClient /></RequireAuthCitoyen> },
        { path: "demandes/ajout-demande/:id", exact: true, element: <RequireAuthCitoyen><AjoutDemande /></RequireAuthCitoyen> },
        { path: 'demandes/:id', element: <RequireAuthCitoyen><DetailDemande /></RequireAuthCitoyen> },
        { path: 'demandes/upload-files', element: <RequireAuthCitoyen><UploadPage /></RequireAuthCitoyen> },
        { path: 'payement', element: <RequireAuthCitoyen><Payement /></RequireAuthCitoyen> },
        { path: 'login', element: <LoginClient /> },
        { path: 'signup', element: <SignupClient /> },
        { path: 'test', element: <TestPage /> }

      ],
    },

    {
      path: '/agent/login',
      element: <Login />,
    },
    {
      path: '/agent/register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/client/papiers" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
