import { Delete, Download, InsertDriveFile } from "@mui/icons-material";
import { Alert, Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deletePieceJointe, downloadFile, getPieceJointByIdDemande } from "src/service/FileService";
import { useAuth } from "src/utils/auth";
import UploadForm from "../file/UploadForm";
import { AgentLoaderMini } from "../AgentLoader";


const FichierListe = (props) => {
    const auth = useAuth();
    const agent = auth.getAgent();
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    const [rows, setRows] = useState([]);
    const nextUrl = "/client/demandes/" + props.idDemande + "?new-file";

    /*modal detail handler*/

    const [data, setData] = useState({});

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const [refresh, setRefresh] = useState(true);
    const clickDelete = (newData) => {
        setData(newData);
        handleOpenDelete();
    };

    const [load, setLoad] = useState(true);
    const [error, setError] = useState(null);
    const clickDownload = async (piece) => {
        try {
            await downloadFile(piece.id, piece.piece);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        getPieceJointByIdDemande(props.idDemande).then(response => {
            setRows(response.data.items);
            setLoad(false);
            setRefresh(false);
        }).catch(error => {
            setError(error.response.data.message);
            setRefresh(false);
        })


    }, [refresh]);

    return (

        (load === true ? (
            <AgentLoaderMini />
        ) : (
            <>
                <Modal
                    centered
                    isOpen={open}
                    toggle={handleClose}
                    className="modal-dialog-scrollable" >
                    <ModalHeader toggle={handleClose}>Ajout Fichier</ModalHeader>
                    <ModalBody>
                        <UploadForm idDemande={props.idDemande}
                            nextUrl={nextUrl}
                            handleClose={handleClose}
                            refresh={() => setRefresh(true)}
                        />
                    </ModalBody>

                </Modal>
                <ModalDeleteFile
                    data={data}
                    open={openDelete}
                    handleClose={handleCloseDelete}
                    refresh={() => setRefresh(true)}
                    handleOpenSuccess={(message) => props.handleOpenSuccess(message)}
                />
                <Typography variant="h4" align="center">
                    Pièce justificative
                </Typography>
                <Stack spacing={2} >
                    {error !== null ? (
                        <Alert variant="outlined" severity="error">
                            {error}
                        </Alert>
                    ) : (
                        <Demo>
                            <List>
                                {rows.map(row => (
                                    <ListItem key={row.id}
                                        secondaryAction={
                                            agent === null ?
                                                < IconButton onClick={() => clickDelete(row)} edge="end" aria-label="delete">
                                                    <Delete />
                                                </IconButton>
                                                :
                                                < IconButton onClick={() => { clickDownload(row) }} edge="end" aria-label="delete">
                                                    <Download />
                                                </IconButton>

                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <InsertDriveFile />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={row.piece}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            {agent === null && (
                                <Button fullWidth variant="contained" onClick={handleOpen}> AJOUTER FICHIER(S)</Button>
                            )}
                        </Demo>
                    )}
                </Stack>
            </>
        ))

    );
}

export default FichierListe;

const ModalDeleteFile = (props) => {
    const handleSubmit = async () => {
        await deletePieceJointe(props.data.id).then(response => {
            props.handleOpenSuccess(response.data.message);
            props.handleClose();
            props.refresh();

        }).catch(error => {
            console.error(error);
        })
    };
    return (
        <>
            <Modal
                centered
                isOpen={props.open}
                toggle={props.handleClose}
                className="modal-dialog-scrollable" >
                <ModalHeader toggle={props.handleClose}>Confirmation de suppresion</ModalHeader>
                <ModalBody>
                    Êtes-vous sur de supprimer  <strong>{props.data.piece}</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button variant="outlined" color="error" onClick={handleSubmit} >Supprimer</Button>
                    <Button variant="outlined" onClick={props.handleClose} >Fermer</Button>
                </ModalFooter>
            </Modal>
        </>
    );

}