import { Typography } from '@mui/material';
import DemandeTable from 'src/components/demande/DemandeTable';

const DemandeAdmin = () => {
  return (
    <>
      <Typography variant="h3">Liste des demandes</Typography>
      <DemandeTable />
    </>
  );
};

export default DemandeAdmin;
