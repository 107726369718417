import { Check, Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getDemandeByAgent } from 'src/service/DemandePapierService';
import { useAuth } from 'src/utils/auth';
import { fDateSql, fDateTimeFr } from 'src/utils/formatTime';
import StatusDemande from '../StatusDemande';
import { FormProvider, RHFTextField } from '../hook-form';
import { RHFTextFieldDate } from '../hook-form/RHFTextField';
import ModalRdv from './ModalRdv';
import AgentLoader from '../AgentLoader';

const DemandeTable = () => {
  const idAgent = useAuth().getAgent().idAgent;
  // const idAgent = Session.get('agent').idAgent;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const redirectDetail = (idDemande) => {
    const path = '/agent/demandes/' + idDemande;

    navigate(path);
  };

  /*recherche avance*/
  const defaultSearch = {
    id: null,
    demande: null,
    status: null,
    dateMin: null,
    dateMax: null,
  };
  const [myData, setMyData] = useState(defaultSearch);
  const status = [
    { id: 1, nom: 'Tous' },
    { id: 3, nom: 'En attente' },
    { id: 5, nom: 'Validée' },
    { id: 4, nom: 'Incomplète' },
    { id: 6, nom: 'Finie' },
  ];

  const methods = useForm({ defaultSearch });
  /*on submit*/
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    console.log('submited');
    // setLoading(true);

    if (data.id == '') {
      data.nom = null;
    }
    if (data.demande === '') {
      data.demande = null;
    }
    if (data.status === 1) {
      data.status = null;
    }

    if (!(typeof data.dateMin === 'undefined')) {
      data.dateMin = fDateSql(data.dateMin);
    }
    if (!(typeof data.dateMax === 'undefined')) {
      data.dateMax = fDateSql(data.dateMax);
    }

    setMyData(data);
    console.log(data);
  };

  /*pagination*/
  const [page, setPage] = useState(1);
  const [pageNbr, setPageNbr] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setLoading(true);
      getDemandeByAgent(idAgent, page - 1, myData).then((response) => {
        setRows(response.data.items.content);
        setPageNbr(response.data.items.totalPages);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, [page, myData]);
  const ButtonDemande = (props) => {
    return (
      <>
        <Button
          size="small"
          variant="contained"
          color="info"
          startIcon={<Visibility />}
          onClick={() => {
            redirectDetail(props.data.idDemande);
          }}
        >
          Détails
        </Button>
        {props.data.etat === 5 && (
          <Button
            size="small"
            variant="contained"
            sx={{ marginLeft: 2 }}
            color="success"
            startIcon={<Check />}
            onClick={() => {
              props.changeData(props.data);
              handleOpen();
            }}
          >
            Finie
          </Button>
        )}
      </>
    );
  };
  if (isLoading) {
    return <AgentLoader />;
  } else {
    return (
      <>
        <ModalRdv idDemande={data.idDemande} demande={data.demande} open={open} handleClose={handleClose} />
        {/*recherche avancee */}
        <Box my={1}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item lg={2} xs={12}>
                <RHFTextField name="id" size="small" label="ID" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={2} xs={12}>
                <RHFTextField name="demande" size="small" label="Demande" variant="outlined" fullWidth />
              </Grid>

              <Grid item lg={2} xs={6}>
                <RHFTextFieldDate
                  name="dateMin"
                  size="small"
                  label="Date Min"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item lg={2} xs={6}>
                <RHFTextFieldDate
                  name="dateMax"
                  size="small"
                  label="Date Max"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid item lg={2} xs={6}>
                <RHFTextField name="status" size="small" select label="Status" variant="outlined" fullWidth>
                  {status.map((item) => (
                    <MenuItem key={'status' + item.id} value={item.id}>
                      {' '}
                      {item.nom}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
              <Grid item>
                <Button variant="contained" type="submit" size="medium">
                  RECHERCHER
                </Button>
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
        {/*table */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell align="right">ID</TableCell>
                <TableCell>Demande</TableCell>
                <TableCell align="right">Nombre</TableCell>
                <TableCell sx={{ minWidth: 100 }} align="center">
                  Date
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell sx={{ minWidth: 220 }} align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="right">{row.idDemande}</TableCell>
                  <TableCell>{row.demande}</TableCell>
                  <TableCell align="right">{row.examplaire}</TableCell>
                  <TableCell align="center">{fDateTimeFr(row.dateHeure)}</TableCell>
                  <TableCell>
                    <StatusDemande idStatus={row.etat} />
                  </TableCell>
                  <TableCell>
                    <ButtonDemande data={row} changeData={setData} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/*Pagination */}
          <Stack spacing={2} m={2}>
            <Pagination color="primary" count={pageNbr} page={page} onChange={handleChange} />
          </Stack>
        </TableContainer>
      </>
    );
  }
};

export default DemandeTable;
