import { CloseOutlined } from '@mui/icons-material';
import { Alert, Collapse, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
MessageClose.propTypes = {
  message: PropTypes.string.isRequired,
};

export default function MessageClose({ message, ...others }) {
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseOutlined fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        {...others}
      >
        {message}
      </Alert>
    </Collapse>
  );
}
