import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";


const axiosInstance = axios.create({
    baseURL: siteUrl("forms")
});
export const newForm = async (formData) => {

    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await axios.post(siteUrl("forms"), JSON.stringify(formData), customConfig);

}


export const modifyFormValue = async (formData) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return await axios.put(siteUrl("forms"), JSON.stringify(formData), customConfig);
}



export function getValueFormByIdDemande(idDemande) {
    return axiosInstance.request({
        method: "GET",
        url: "/demandes/" + idDemande
    });
}

export function getFormByIdPapier(idPapier) {
    return axiosInstance.request({
        method: "GET",
        url: "/" + idPapier
    });
}
