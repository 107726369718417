import { Button, MenuItem, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getSansCompte, newCompteAgent } from 'src/service/AgentService';

const ModalNewCompteAgent = (props) => {
  const defaultValues = {
    idAgent: 0,
    email: '',
    telephone: '',
    mdp: '',
    niveau: 0,
  };
  const navigate = useNavigate();
  const [values, setValues] = useState(defaultValues);
  const [agents, setAgents] = useState([]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getSansCompte().then((response) => {
      setAgents(response.data.items);
    });
  }, []);

  const handleSubmit = async () => {
    // console.log(values)
    await newCompteAgent(values).then((response) => {
      if (response.status === 200) {
        // navigate('/agent/profiles?new');
        window.location.reload();
      }
    });
  };

  return (
    <>
      <Modal centered isOpen={props.open} toggle={props.handleClose} className="modal-dialog-scrollable">
        {/* <ModalHeader toggle={props.handleClose}> </ModalHeader> */}
        <ModalBody>
          <Stack spacing={2}>
            <TextField
              type="text"
              required
              label="Agent"
              id="idAgent"
              name="idAgent"
              value={values.idAgent}
              fullWidth
              variant="outlined"
              onChange={onChange}
              select
            >
              {agents.map((agent) => (
                <MenuItem key={agent.id} value={agent.id}>
                  {agent.id + ' | ' + agent.nom + ' ' + agent.prenom + ' (' + agent.fonction + ')'}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="email"
              required
              label="Email"
              id="email"
              name="email"
              value={values.email}
              fullWidth
              variant="outlined"
              onChange={onChange}
            />
            <TextField
              type="text"
              required
              label="Mot de passe"
              id="mdp"
              name="mdp"
              value={values.mdp}
              fullWidth
              variant="outlined"
              onChange={onChange}
            />
            <TextField
              type="text"
              required
              label="Téléphone"
              id="telephone"
              name="telephone"
              value={values.telephone}
              fullWidth
              variant="outlined"
              onChange={onChange}
            />
            <TextField
              type="text"
              required
              label="Niveau"
              id="niveau"
              name="niveau"
              value={values.niveau}
              fullWidth
              variant="outlined"
              onChange={onChange}
              select
            >
              <MenuItem value={0}> Simple utilisateur </MenuItem>
              <MenuItem value={1}> Modérateur</MenuItem>
              <MenuItem value={2}> Administrateur</MenuItem>
            </TextField>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" onClick={handleSubmit}>
            Valider
          </Button>
          <Button variant="outlined" onClick={props.handleClose}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalNewCompteAgent;
