import { Person, Send } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { green } from '@mui/material/colors';
import axios from 'axios';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTextArea,
} from 'mdb-react-ui-kit';
import { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { getChatByIdDemande } from 'src/service/ChatService';
import { useAuth } from 'src/utils/auth';
import { siteUrl } from 'src/utils/baseUrl';

const MyMessage = (props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-end mb-4">
        <div className="p-3 me-3 border" style={{ borderRadius: '15px', backgroundColor: '#fbfbfb' }}>
          <p className="small mb-0">{props.data.message}</p>
        </div>
        {/* <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava2-bg.webp"
                    alt="avatar 1"
                    style={{ width: "45px", height: "100%" }}
                /> */}
        {props.owner === 'citoyen' ? (
          <Avatar>
            <Person />
          </Avatar>
        ) : (
          <Avatar sx={{ bgcolor: green[500] }}>CM</Avatar>
        )}
      </div>
    </>
  );
};

const OthersMessage = (props) => {
  return (
    <>
      <div className="d-flex flex-row justify-content-start mb-4">
        {props.owner === 'citoyen' ? (
          <Avatar>
            <Person />
          </Avatar>
        ) : (
          <Avatar sx={{ bgcolor: green[500] }}>CM</Avatar>
        )}
        <div
          className="p-3 ms-3"
          style={{
            borderRadius: '15px',
            backgroundColor: 'rgba(57, 192, 237,.2)',
          }}
        >
          <p className="small mb-0">{props.data.message}</p>
        </div>
      </div>
    </>
  );
};

const MessageRow = (props) => {
  return props.owner === 'citoyen' ? (
    props.data.idCitoyen !== null ? (
      <MyMessage owner={'citoyen'} data={props.data} />
    ) : (
      <OthersMessage owner={'agent'} data={props.data} />
    )
  ) : props.data.idAgent !== null ? (
    <MyMessage owner={'agent'} data={props.data} />
  ) : (
    <OthersMessage owner={'citoyen'} data={props.data} />
  );
};

const Chat = (props) => {
  const [messages, setMessages] = useState([]);
  const url = siteUrl('chats');
  const auth = useAuth();
  useEffect(() => {
    getChatByIdDemande(props.idDemande).then((response) => setMessages(response.data.items));
  }, []);

  const [reponse, setReponse] = useState('');

  const sendMessage = async () => {
    try {
      // console.log('idDemande ' + props.idDemande);
      // console.log('message ' + reponse);
      // console.log('idCitoyen  ' + auth.citoyen.idCitoyen);
      // console.log('idAgent  ' + auth.agent.idAgent);

      const parameters = {
        idDemande: props.idDemande,
        message: reponse,
      };
      if (auth.getAgent() !== null) {
        parameters.idAgent = auth.getAgent().idAgent;
      }
      if (auth.getCitoyen() !== null) {
        parameters.idCitoyen = auth.getCitoyen().idCitoyen;
      }
      console.log(parameters);

      return axios
        .post(url, null, {
          params: parameters,
        })
        .then((response) => {
          if (response.status === 200) window.location.reload();
        });
      // setResponse(res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <MDBContainer>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="8" lg="6" xl="12">
            <MDBCard id="chat1" style={{ borderRadius: '15px' }}>
              <MDBCardHeader
                className="d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
                style={{
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                }}
              >
                <MDBIcon fas icon="angle-left" />
                <p className="mb-0 fw-bold">Discussion</p>
                <MDBIcon fas icon="times" />
              </MDBCardHeader>

              <MDBCardBody>
                {messages.length === 0 ? (
                  <Alert color="dark">
                    <center>Vous pouvez poser vos question ici.</center>
                  </Alert>
                ) : (
                  messages.map((item, i) => <MessageRow owner={props.owner} key={i} data={item} />)
                )}

                <MDBTextArea
                  className="form-outline"
                  id="textAreaExample"
                  value={reponse}
                  onChange={(e) => {
                    setReponse(e.target.value);
                  }}
                  rows={4}
                />
                <MDBBtn onClick={sendMessage} color="info" rounded className="float-end mt-2">
                  Envoyé <Send />
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Chat;
