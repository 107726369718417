import { Box, Button, styled, Typography } from "@mui/material";

const ContentStyle = styled('div')(({ }) => ({
    // maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    // padding: theme.spacing(12, 0)
}));

const ErrorPage = (props) => {

    return (
        <>
            <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                <Box
                    component="img"
                    src="/static/illustrations/error.png"
                    sx={{ height: 300, mx: 'auto', my: { xs: 2, sm: 2 } }}
                />
                {props.message !== null && (
                    <Typography variant="h3">
                        {props.message}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    onClick={() => { window.location.reload() }}
                >REFRAICHIR</Button>
            </ContentStyle>
        </>
    );
}

export default ErrorPage;