import { Avatar, Box, Button, Card, Container, Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AgentLoaderMini } from "src/components/AgentLoader";
import Page from "src/components/Page";
import AgentStatus from "src/components/agent/AgentStatus";
import PapierAutorised from "src/components/papier/PapierAutorised";
import { getNiveau, getProfileByIdProfile } from "src/service/AgentService";
import { siteUrl } from "src/utils/baseUrl";
import { formatMoney } from "src/utils/formatNumber";
import { fDateFr } from "src/utils/formatTime";
import ErrorPage from "./ErrorPage";
import { ModeEdit } from "@mui/icons-material";

const DetailCompteAgent = () => {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    useEffect(() => {
        getProfileByIdProfile(id).then((response) => {
            setData(response.data.items);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            if (error.response.status) {
                console.error(error.response.data.message);
                setError(error.response.data.message)
            } else {
                setError("impossible de charger la page. Veuillez refraichir la page!");
                console.error(error);
            }
        }
        )
    }, [])
    return (
        <>
            <Page title="detail compte">
                <Container>
                    {error !== null ? (
                        <ErrorPage message={error} />
                    ) : (
                        <>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {isLoading ? (<AgentLoaderMini />) :
                                        (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                                <Avatar sx={{ width: 200, height: 200 }} src={siteUrl("files/agent/" + data.photo)} />
                                            </Box>

                                        )
                                    }
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Card elevation={5} >
                                        {isLoading ? (<AgentLoaderMini />) :
                                            (
                                                <Grid container margin={2}>
                                                    <Grid item lg={3} md={3} sm={4} xs={3}><strong>Status:</strong></Grid> <Grid item lg={9} md={9} sm={8} xs={9}><AgentStatus active={data.activeProfil} /></Grid>
                                                    <Grid item lg={3} md={3} sm={4} xs={3}><strong>Niveau:</strong></Grid> <Grid item lg={9} md={9} sm={8} xs={9}>{getNiveau(data.niveau)}</Grid>
                                                    <Grid item lg={3} md={3} sm={4} xs={3}><strong>Email Pro:</strong></Grid> <Grid item lg={9} md={9} sm={8} xs={9}>{data.emailPro}</Grid>
                                                    <Grid item lg={3} md={3} sm={4} xs={3}><strong>Tel Pro:</strong></Grid> <Grid item lg={9} md={9} sm={8} xs={9}>{data.telPro}</Grid>
                                                </Grid>
                                            )}

                                    </Card>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Card elevation={5} >
                                        {isLoading ? (<AgentLoaderMini />) :
                                            (
                                                <Box margin={2}>
                                                    <Typography variant="h5">
                                                        À propos
                                                    </Typography>
                                                    <Divider sx={{ borderColor: 'black', mb: 2 }} />
                                                    <Grid container margin={2} >
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Nom: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.nom} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Prénom: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.prenom} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Fonction: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.fonction} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Date de naissance: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{fDateFr(data.dateNaissance)} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Lieu de naissance: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.lieuNaissance} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Adresse: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.adresse} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Numero CIN: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.cin} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Date CIN: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{fDateFr(data.dateCin)} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Lieux CIN: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.lieuCin} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Téléphone: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.telephone} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Email: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.email} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Salaire: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{formatMoney(data.salaire)} </Grid>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><strong>Sexe: </strong> </Grid> <Grid item lg={8} md={8} sm={8} xs={8}>{data.sexe === 'm' ? 'homme' : 'femme'} </Grid>
                                                    </Grid>
                                                    <Divider sx={{ borderColor: 'black', my: 1 }} />
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Button variant="outlined" endIcon={<ModeEdit />}>MODIFIER</Button>
                                                    </Box>
                                                </Box>
                                            )}

                                    </Card>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Card elevation={5} >
                                        <PapierAutorised id={id} />
                                    </Card>
                                </Grid>
                            </Grid>

                        </>
                    )}



                </Container>
            </Page>
        </>
    );
}

export default DetailCompteAgent;