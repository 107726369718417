import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";



const axiosInstance = axios.create({
    baseURL: siteUrl("demandes-papiers")
});


export function getChiffreAffaire(data) {
    return axiosInstance.request({
        method: "GET",
        url: "dashboards/dates",
        params:data
    });
}

export const ajoutDemandeV2 = async (formData) => {

    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        return await axios.post(siteUrl("demandes-papiers/v2"), JSON.stringify(formData), customConfig);
    } catch (error) {
        console.log(error);
    }

}

export const createDemande = async (data) => {
    const url = siteUrl("demandes-papiers");
    try {
        return await axios.post(url, null, {
            params: data
        });
    } catch (error) {
        console.error(error);
    }
};

export function getDemandeByCitoyen(idCitoyen) {
    return axiosInstance.request({
        method: "GET",
        url: "?id-citoyen=" + idCitoyen
    });
}

export function getDemandeById(id) {
    return axiosInstance.request({
        method: "GET",
        url: "/" + id
    });
}

// export function getDemandeByAgent(idAgent) {
//     return axiosInstance.request({
//         method: "GET",
//         url: "/agents/" + idAgent
//     });
// }

export function getDemandeByAgent(idAgent, pageTarget, dataBody) {
    const customConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {page: pageTarget, size:20},
    };
    console.log(dataBody);
    return axios.post(siteUrl( "demandes-papiers/agents/" + idAgent),  JSON.stringify(dataBody), customConfig);
  
  }