import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AgentLoaderBackDrop } from 'src/components/AgentLoader';
import Page from 'src/components/Page';
import { newForm } from 'src/service/FormulaireService';

const CreateForm = () => {
  const [openAjout, setOpenAjout] = useState(false);
  const handleOpenAjout = () => setOpenAjout(true);
  const handleCloseAjout = () => setOpenAjout(false);
  const [newField, setNewField] = useState({ nom: '', type: 'text', required: true });
  const [fields, setFields] = useState([]);

  /*get last papier*/
  const location = useLocation();
  const { lastPapier } = location.state || {};

  useEffect(() => {
    'niova field';
  }, [fields]);
  const onChangeNewField = (e) => {
    setNewField({ ...newField, [e.target.name]: e.target.value });
  };
  /*controle */
  const addField = () => {
    console.log('added');
    handleOpenAjout();
  };

  const valideAddField = (e) => {
    console.log('valider');
    console.log(newField);
    fields.push(newField);
    // newField.nom = "";
    handleCloseAjout();
    console.log(fields);
  };

  const deleteField = (fieldToRemove) => {
    console.log('delete');
    setFields(fields.filter((field) => field !== fieldToRemove));
    console.log(fields);
  };

  const upField = (field) => {
    console.log('up field');
    const myIndex = fields.indexOf(field);
    console.log('My index: ' + myIndex);

    if (myIndex !== 0) {
      let tempFields = [...fields];
      tempFields.splice(myIndex, 1);
      tempFields.splice(myIndex - 1, 0, field);
      console.log('up change: ', tempFields);
      setFields(tempFields);
    } else {
      console.log('First field');
    }
  };

  const downField = (field) => {
    console.log('down field');
    const myIndex = fields.indexOf(field);
    console.log('My index: ' + myIndex);

    if (myIndex !== fields.length - 1) {
      let tempFields = [...fields];
      tempFields.splice(myIndex, 1);
      tempFields.splice(myIndex + 1, 0, field);
      console.log('down change: ', tempFields);
      setFields(tempFields);
    } else {
      console.log('last field');
    }
  };

  const vider = () => {
    console.log('vider');
    setFields([]);
  };

  /*validation*/

  const navigate = useNavigate();

  const valider = () => {
    setStateBackDrop(true);
    console.log('valider');
    for (let i = 0; i < fields.length; i++) {
      fields[i]['ordre'] = i + 1;
    }
    // const jsonFields = JSON.stringify(fields);
    // console.log('apres validation', jsonFields);

    const myForm = { id: lastPapier.idPapier, champ: fields };
    newForm(myForm)
      .then((response) => {
        setStateBackDrop(false);
        navigate('/agent/papier-admins');
      })
      .catch((error) => {
        setStateBackDrop(false);
        // console.log(error.response.status);
        console.log(error.response.data.message);
        // setError(error.response.data.message);
      });
  };

  /*backdrop*/
  const [stateBackDrop, setStateBackDrop] = useState(false);

  return (
    <>
      <AgentLoaderBackDrop open={stateBackDrop} />
      <Page title="creation form">
        <Modal centered isOpen={openAjout} toggle={handleCloseAjout}>
          <ModalHeader toggle={handleCloseAjout}>
            <Typography>Nouveau Champ</Typography>
          </ModalHeader>
          <ModalBody>
            <Stack spacing={2}>
              <TextField label="Type" name="type" value={newField.type} select onChange={onChangeNewField} required>
                <MenuItem key={0} value="text">
                  Text
                </MenuItem>
                <MenuItem key={1} value="date">
                  Date
                </MenuItem>
              </TextField>
              <TextField type="text" name="nom" label="Désignation" required onChange={onChangeNewField} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button color="inherit" onClick={handleCloseAjout}>
              ANNULER
            </Button>
            <Button color="primary" onClick={valideAddField}>
              VALIDER
            </Button>{' '}
          </ModalFooter>
        </Modal>
        <Typography variant="h3" align="center">
          Création de formulaire ({lastPapier.nom})
        </Typography>
        <Grid container>
          <Grid item xs={12} md={3} lg={3}></Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
              <Button onClick={addField} variant="outlined">
                {' '}
                AJOUT
              </Button>
            </Stack>
            <Stack direction="column" mt={2} spacing={1}>
              {fields.map((field, index) => (
                <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={1} key={index}>
                  <TextField sx={{ flexGrow: 0 }} label={`${field.nom} (${field.type})`} />
                  <IconButton
                    onClick={() => {
                      deleteField(field);
                    }}
                  >
                    {' '}
                    <Delete />{' '}
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      upField(field);
                    }}
                  >
                    {' '}
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      downField(field);
                    }}
                  >
                    {' '}
                    <ArrowDownward />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} lg={3}></Grid>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            p: 1,
            m: 1,
          }}
        >
          <Button color="inherit" onClick={vider}>
            RETOUR
          </Button>
          <Stack direction={'row'}>
            <Button color="inherit" onClick={vider}>
              VIDER
            </Button>
            <Button onClick={valider}>VALIDER</Button>
          </Stack>
        </Box>
      </Page>
    </>
  );
};

export default CreateForm;
