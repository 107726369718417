import { Alert, Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { AgentLoaderMini } from '../AgentLoader';
import { getValueFormByIdDemande } from 'src/service/FormulaireService';
import { useParams } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import FormulaireDemande from './FormulaireDemande';

const ValueFormDemande = (props) => {
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    getValueFormByIdDemande(id)
      .then((response) => {
        setData(response.data.items);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.error(error);
        setError(error.response.data.message);
      });
  }, []);

  return load === true ? (
    <AgentLoaderMini />
  ) : (
    <>
      <Typography variant="h4" align="center" mb={2}>
        {' '}
        Formulaire de demande
      </Typography>
      {error == !null ? (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      ) : (
        <>
          <Modal centered isOpen={open} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>{'Modification'}</ModalHeader>
            <ModalBody>
              <FormulaireDemande
                type="modify"
                data={data}
                papierData={props.papierData}
                handleClose={handleClose}
                handleOpenSuccess={(message) => props.handleOpenSuccess(message)}
              />
            </ModalBody>
          </Modal>
          <Stack direction={'column'} spacing={2}>
            {data.map((row, index) => (
              // <Box key={`value-${index}`}>

              <Grid key={`value-${index}`} container m={0}>
                <Grid item xs={4} lg={4}>
                  <Typography variant="body1" fontWeight={'bold'}>
                    {row.nom + ':'}
                  </Typography>
                </Grid>
                <Grid item xs={8} lg={8}>
                  {row.value}
                </Grid>
              </Grid>
              // </Box>
            ))}
            {(props.papierData && props.papierData.etat) < 5 && <Button onClick={handleOpen}>MODIFIER</Button>}
          </Stack>
        </>
      )}
    </>
  );
};

export default ValueFormDemande;
