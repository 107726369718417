import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AgentLoader from 'src/components/AgentLoader';
import { FormProvider } from 'src/components/hook-form';
import { RHFTextFieldDate } from 'src/components/hook-form/RHFTextField';
import { AppCurrentVisits } from 'src/sections/@dashboard/app';
import { ChartLabelsString } from 'src/sections/@dashboard/app/AppWebsiteVisits';
import { getChiffreAffaire } from 'src/service/DemandePapierService';
import { fDateSql2 } from 'src/utils/formatTime';

const { default: Page } = require('src/components/Page');

const DashboardPapier = () => {
  const [isLoading, setLoading] = useState(true);

  const [labelDate, setLabelDate] = useState([]);
  const [valueDate, setValueDate] = useState([]);
  const [type, setType] = useState([]);

  const theme = useTheme();
  /*recherche avance*/
  const defaultSearch = {
    dateMin: '2022-01-01',
    dateMax: '2024-01-01',
  };
  const methods = useForm({ defaultSearch });
  /*on submit*/
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    console.log('submited');
    data.dateMin = fDateSql2(data.dateMin);
    data.dateMax = fDateSql2(data.dateMax);
    console.log(data);
    setLoading(true);
    getChiffreAffaire(data).then((response) => {
      setLabelDate(response.data.chiffreDate.label);
      setValueDate(response.data.chiffreDate.value);
      setType(response.data.chiffreType);
      setLoading(false);
    });
  };

  useEffect(() => {
    getChiffreAffaire(defaultSearch)
      .then((response) => {
        setLabelDate(response.data.chiffreDate.label);
        setValueDate(response.data.chiffreDate.value);
        setType(response.data.chiffreType);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (isLoading) {
    return <AgentLoader />;
  } else {
    return (
      <>
        <Page title="dashboard">
          <Container>
            <Typography variant="h3">Tableau de bord</Typography>
            <Box my={1}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} rowSpacing={2}>
                  <Grid item lg={3} xs={6}>
                    <RHFTextFieldDate name="dateMin" size="small" label="Date min" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item lg={3} xs={6}>
                    <RHFTextFieldDate name="dateMax" size="small" label="Date max" variant="outlined" fullWidth />
                  </Grid>

                  <Grid item lg={2} xs={6}>
                    <Button variant="contained" type="submit" size="medium">
                      RECHERCHER
                    </Button>
                  </Grid>
                </Grid>
              </FormProvider>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={8}>
                <ChartLabelsString
                  title="Chiffre d'affaire"
                  chartLabels={labelDate}
                  chartData={[
                    {
                      name: 'montant',
                      type: 'line',
                      fill: 'solid',
                      data: valueDate,
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="Vente par type"
                  chartData={type}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.chart.blue[0],
                    theme.palette.chart.violet[0],
                    theme.palette.chart.yellow[0],
                  ]}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </>
    );
  }
};

export default DashboardPapier;
