import Label from "./Label";


const StatusDemande = (props) => {
    const status = [
        { id: 1, color: 'default', message: "impayée" },
        { id: 3, color: 'warning', message: 'en attente' },
        { id: 4, color: 'error', message: 'incomplète' },
        { id: 5, color: 'primary', message: 'validée' },
        { id: 6, color: 'success', message: 'finie' },
    ];
    return (
        <>
            {
                status
                    .filter((state) => state.id === props.idStatus)
                    .map((state) => {
                        return (
                            <Label key={state.id} variant="ghost" color={state.color} >
                                {state.message}
                            </Label>
                        );
                    })
            }
        </>
    );
}

export default StatusDemande;