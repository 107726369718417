import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import TableAgent from 'src/components/agent/TableAgent';
import AgentLoader from 'src/components/AgentLoader';
import Page from 'src/components/Page';
import { getAgents } from 'src/service/AgentService';

const Agent = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getAgents().then((response) => {
      setRows(response.data.items);
      setLoading(false);
    });
  }, []);
  if (isLoading) {
    return (
      <>
        <AgentLoader />
      </>
    );
  }
  return (
    <Page title="Agent">
      <TableAgent data={rows} />
    </Page>
  );
};

export default Agent;
