import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getProfileByIdProfile } from "src/service/AgentService";
import { AgentLoaderMini } from "../AgentLoader";
import DetailAgent from "./DetailAgent";

const ModalDetailCompte = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.id !== null) {
            
            getProfileByIdProfile(props.id).then(response => {
                if (response.status === 200) {
                    setData(response.data.items);
                    setLoading(false)
                }
            })
        }
    }, [props.id])
    return (<>
        <Modal
            centered
            isOpen={props.open}
            toggle={props.handleClose}
            className="modal-dialog-scrollable" >
            <ModalHeader toggle={props.handleClose}></ModalHeader>
            <ModalBody>
                {isLoading ? <AgentLoaderMini /> :
                    <>
                        <DetailAgent data={data} pro />
                    </>

                }
            </ModalBody>
            <ModalFooter>
                <Button variant="outlined" onClick={props.handleClose} >Fermer</Button>
            </ModalFooter>
        </Modal>
    </>);
}

export default ModalDetailCompte;