// component
import { AccountCircle, Badge, Description, Groups, HourglassFull, NoteAdd, PersonAdd, PointOfSale, Poll } from '@mui/icons-material';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/agent/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'user',
  //   path: '/agent/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'product',
  //   path: '/agent/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/agent/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  {
    title: 'Dashboard',
    path: '/agent/dashboard-papier',
    icon: <Poll />,
    rank:0,
  },

  {
    title: 'Papier adminstratif',
    path: '/agent/papier-admins',
    icon: <Description />,
    rank:2,
  },
  {
    title: 'Nouveau papier',
    path: '/agent/ajout-papier',
    icon: <NoteAdd />,
    rank:2,
  },
  {
    title: 'Caisse',
    path: '/agent/paiement-invalide',
    icon: <PointOfSale />,
    rank:0,
  },
  {
    title: 'Demande',
    path: '/agent/demandes',
    icon: <HourglassFull />,
    rank:1,
  },
  {
    title: 'Agent',
    path: '/agent/liste',
    icon: <Badge />,
    rank:2,
  },
  {
    title: 'Compte Agent',
    path: '/agent/profiles',
    icon: <AccountCircle />,
    rank:2,
  },
  {
    title: 'population',
    path: '/agent/population',
    icon: <Groups />,
    rank:1,
  },
  {
    title: 'ajout citoyen',
    path: '/agent/ajout-citoyen',
    icon: <PersonAdd />,
    rank:1,
  },
  // {
  //   title: 'login',
  //   path: '/agent/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/agent/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
  // {
  //   title: 'Test',
  //   path: '/agent/test',
  //   icon: <BugReport />,
  // },
];

export default navConfig;
