import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";

const axiosInstance = axios.create({
    baseURL: siteUrl("papiers")
});

export function getPapierChecked(idProfilAgent) {
    return axiosInstance.request({
        method: "GET",
        url: "/checked/" + idProfilAgent
    });
}

export function getPapierAutorised(idProfilAgent) {
    return axiosInstance.request({
        method: "GET",
        url: "/autorized/" + idProfilAgent
    });
}

export function getPapierType() {
    return axiosInstance.request({
        method: "GET",
        url: "/types"
    });
}

export function getPapiers() {
    return axiosInstance.request({
        method: "GET"
    });
}
export function getTypePapier() {
    return axiosInstance.request({
        method: "GET",
        url: "/type-papiers"
    });
};

export function getPapierElement() {
    return axiosInstance.request({
        method: "GET",
        url: "/papier-elements"
    });
};

export function createPapier(papier) {
    const res = null;
    axiosInstance.post('', null, { params: { ...papier } })
        .then((response => { res = response.data; console.log(response) }));

    return res;
}
// const PapierAdminService = () => {




// }

// export default PapierAdminService;