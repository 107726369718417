import { Check, Close } from '@mui/icons-material';
import { Box, Grid, MenuItem, Pagination, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { RHFTextFieldDate } from 'src/components/hook-form/RHFTextField';
import { getUnpaids } from 'src/service/PaiementService';
import { siteUrl } from 'src/utils/baseUrl';
import { fNumber } from 'src/utils/formatNumber';
import { fDateFr } from 'src/utils/formatTime';

const minWidthCol = {
  minWidth: 50,
};
const ModalAnnulation = (props) => {
  const url = siteUrl('paiements/' + props.data.id);
  const deletePaiement = () => {
    try {
      return axios.delete(url, null).then((response) => {
        if (response.status === 200) window.location.reload();
      });
      // setResponse(res);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    deletePaiement();
  };
  return (
    <>
      <Modal centered isOpen={props.open} toggle={props.handleClose}>
        <ModalHeader toggle={props.handleClose}>Annulation</ModalHeader>
        <ModalBody>Êtes-vous sûr de supprimer cette demande?</ModalBody>
        <ModalFooter>
          <Button color="danger" outline startIcon={<Check />} onClick={handleSubmit}>
            {' '}
            Oui{' '}
          </Button>{' '}
          <Button startIcon={<Close />} outline color="primary" onClick={props.handleClose}>
            {' '}
            Non
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ModalConfirmation = (props) => {
  const [ref, setRef] = useState('');
  const url = siteUrl('paiements/' + props.data.id);

  const makePaiement = () => {
    try {
      return axios
        .put(url, null, {
          params: {
            ref: ref,
          },
        })
        .then((response) => {
          if (response.status === 200) window.location.reload();
        });
      // setResponse(res);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    makePaiement();
  };
  return (
    <>
      {props.data !== [] && (
        <Modal centered isOpen={props.open} toggle={props.handleClose}>
          <ModalHeader toggle={props.handleClose}>Validation de paiement</ModalHeader>
          <ModalBody>
            <Grid container>
              <Grid item md={3}>
                <strong>ID:</strong>
              </Grid>
              <Grid item md={9}>
                {props.data.id}
              </Grid>
              <Grid item md={3}>
                <strong>Montant:</strong>
              </Grid>
              <Grid item md={9}>
                {fNumber(props.data.prix) + ' Ar x ' + props.data.examplaire + ' = '}{' '}
                <strong> {props.data.total + ' Ar'} </strong>
              </Grid>
            </Grid>

            <TextField
              required
              id="ref"
              name="ref"
              label="Numero Quitance"
              fullWidth
              sx={{
                mt: 2,
              }}
              onChange={(e) => {
                setRef(e.target.value);
                console.log(ref);
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" startIcon={<Check />} onClick={handleSubmit}>
              {' '}
              Payer{' '}
            </Button>{' '}
            <Button startIcon={<Close />} color="secondary" onClick={props.handleClose}>
              {' '}
              Annuler
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

const PaiementInvalide = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = useState([]);

  const [openSuppr, setOpenSuppr] = useState(false);
  const handleOpenSuppr = () => setOpenSuppr(true);
  const handleCloseSuppr = () => setOpenSuppr(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getUnpaids().then((response) => setRows(response.data.items));
  }, []);

  const getMode = (mode) => {
    if (mode === 'e') {
      return 'espèce';
    }
    if (mode === 't') {
      return 'MVola';
    }
    if (mode === 'o') {
      return 'Orange Money';
    }
    if (mode === 'a') {
      return 'Airtel Money';
    }
  };

  /*recherche avance*/
  const defaultSearch = {
    id: '',
    demande: 0,
    mode: '',
    dateMin: null,
    dateMax: null,
  };

  const demande = [
    { id: 0, nom: 'tous' },
    { id: 1, nom: 'Acte de vente moto' },
    { id: 2, nom: '	Bulletin de naissance' },
  ];

  const mode = [
    { id: 0, nom: 'tous' },
    { id: 1, nom: 'Telma' },
    { id: 2, nom: 'Airtel' },
    { id: 3, nom: 'Orange' },
  ];

  const methods = useForm({ defaultSearch });
  /*on submit*/
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    console.log('submited');
    console.log(data);
  };

  /*pagination*/
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <>
      <ModalAnnulation data={modalData} open={openSuppr} handleClose={handleCloseSuppr} />
      <ModalConfirmation data={modalData} open={open} handleClose={handleClose} />
      <Typography variant="h3">Paiement non validés</Typography>
      {/*recherche avancee */}
      <Box my={1}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item lg={2} xs={12}>
              <RHFTextField name="id" size="small" label="ID" variant="outlined" fullWidth />
            </Grid>
            <Grid item lg={2} xs={6}>
              <RHFTextField name="type" size="small" select label="Type" variant="outlined" fullWidth>
                {demande.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {' '}
                    {item.nom}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item lg={2} xs={6}>
              <RHFTextField name="mode" size="small" select label="Mode" variant="outlined" fullWidth>
                {mode.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {' '}
                    {item.nom}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Grid>
            <Grid item lg={2} xs={6}>
              <RHFTextFieldDate
                name="dateMin"
                size="small"
                label="Date Min"
                variant="outlined"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item lg={2} xs={6}>
              <RHFTextFieldDate
                name="dateMax"
                size="small"
                label="Date Max"
                variant="outlined"
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit" size="medium">
                RECHERCHER
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
      {/*table */}
      <div className="table-responsive-xl">
        <Table>
          <thead>
            <tr>
              <th style={minWidthCol} className="fw-bolder text-start">
                ID
              </th>
              <th className="fw-bolder text-start">Demande</th>
              <th className="fw-bolder text-center">Date</th>
              <th className="fw-bolder text-center">Mode</th>
              <th className="fw-bolder text-end">P.U</th>
              <th className="fw-bolder text-end">Nombre</th>
              <th className="fw-bolder text-end">Total</th>
              <th style={minWidthCol}></th>
            </tr>
          </thead>

          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className="align-middle">
                <td className="text-start">{row.id}</td>
                <td className="text-start">{row.demande}</td>
                <td className="text-center">{fDateFr(row.dateHeure)}</td>
                <td className="text-center">{getMode(row.modePaiement)}</td>
                <td className="text-end">{fNumber(row.prix)} Ar </td>
                <td className="text-end">{row.examplaire}</td>
                <td className="text-end">{fNumber(row.total)} Ar </td>
                <td className="text-center">
                  <Button
                    color="success"
                    onClick={() => {
                      setModalData(row);
                      handleOpen();
                    }}
                  >
                    <Check />
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => {
                      setModalData(row);
                      handleOpenSuppr();
                    }}
                    className="ms-3"
                  >
                    <Close />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/*Pagination */}
      <Stack spacing={2}>
        <Pagination color="primary" count={10} page={page} onChange={handleChange} />
      </Stack>
    </>
  );
};

export default PaiementInvalide;
