import { Alert, Box, Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import 'dayjs/locale/fr';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getFormByIdPapier, modifyFormValue } from 'src/service/FormulaireService';
import AgentLoader from '../AgentLoader';
import { fNumber } from 'src/utils/formatNumber';
import { useAuth } from 'src/utils/auth';
import { Check, ControlCameraSharp, ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ajoutDemandeV2 } from 'src/service/DemandePapierService';
import { LoadingButton } from '@mui/lab';
import ErrorPage from 'src/pages/ErrorPage';

const FormulaireDemande = (props) => {
  const [data, setData] = useState(null);
  const [papier, setPapier] = useState(null);
  const [dateValue, setDateValue] = useState([]);
  const [load, setLoad] = useState(true);
  const [defaultPrix, setDefaultPrix] = useState(0);
  const [examplaire, setExamplaire] = useState(1);
  const [prixLabel, setPrixLabel] = useState('');
  const auth = useAuth();
  const citoyen = auth.getCitoyen();
  const navigate = useNavigate();
  const [loadValider, setLoadValider] = useState(false);
  const [errorSend, setErrorSend] = useState(null);
  const [errorChargement, setErrorChargement] = useState(null);
  useEffect(() => {
    if (typeof props.data === 'undefined') {
      console.log('tsy misy props.data..... ');
      getFormByIdPapier(props.idPapier)
        .then((response) => {
          setData(JSON.parse(response.data.champ));
          setPapier(response.data);
          setDefaultPrix(response.data.tarifAutre);
          setPrixLabel(fNumber(response.data.tarifAutre));
          console.log('dataPure', response.data);
          console.log('data:', JSON.parse(response.data.champ));
          return JSON.parse(response.data.champ);
        })
        .catch((error) => {
          console.error(error);
          setLoad(false);
          setErrorChargement(error.response.data.message);
        })
        .then((data) => {
          let tempIndex = 0;
          const now = new Date();
          const dateFields = [];
          data.map((champ) => {
            if (champ.type === 'date') {
              // dateFieldsObject[champ.nom] = '';
              dateFields.push({ name: champ.nom, value: now, index: tempIndex });
              tempIndex = tempIndex + 1;
              // dateFields[champ.nom] = ''
            }
          });
          setDateValue(dateFields);
          setLoad(false);
        })
        .catch((error) => {
          console.error(error);
          setLoad(false);
          setErrorChargement('Erreur de chargment!');
        });
    } else {
      try {
        console.log('props.data:', props.data);
        setData(props.data);
        let tempIndex = 0;
        const dateFields = [];
        props.data.map((champ) => {
          if (champ.type === 'date') {
            console.log('miditra map: ' + tempIndex);
            // dateFieldsObject[champ.nom] = '';
            let [day, month, year] = champ.value.toString().split('/');
            let formattedDateString = `${month}/${day}/${year}`;
            dateFields.push({ name: champ.nom, value: new Date(formattedDateString), index: tempIndex });
            tempIndex = tempIndex + 1;
            // dateFields[champ.nom] = ''
          }
        });
        setDateValue(dateFields);

        setPapier(props.papierData);
        setLoad(false);
      } catch (error) {
        console.error(error);
      }
    }

    /*Ajout*/
  }, []);

  const { register, handleSubmit } = useForm();
  const handleDateChange = (index, date) => {
    setDateValue((prevDates) => {
      const newDates = [...prevDates];
      newDates[index].value = date;
      return newDates;
    });
    // dateFields[fieldTarget] = date;
  };
  const onSubmit = (dataText) => {
    setLoadValider(true);
    for (let i = 0; i < data.length; i++) {
      if (data[i].type === 'date') {
        let index = getIndex(data[i].nom);
        data[i].value = formatDate(dateValue[index].value);
      } else {
        data[i].value = dataText[data[i].nom];
      }
    }

    const formData = {
      idPapier: papier.id,
      idProfilClient: citoyen.idCitoyen,
      examplaire: examplaire,
      valueForms: data,
    };

    if (props.type === 'add') {
      ajoutDemandeV2(formData)
        .then((response) => {
          console.log(response.data);
          setLoadValider(false);
          navigate('/client/demandes/upload-files?idDemande=' + response.data.idDemande);
        })
        .catch((error) => {
          console.error(error);
          setErrorSend(error.response.data.message);
          setLoadValider(false);
        });
    } else {
      // only change value Forms
      modifyFormValue(data)
        .then((response) => {
          props.handleOpenSuccess(response.data.message);
          setLoadValider(false);
          props.handleClose();
        })
        .catch((error) => {
          console.error(error);
          setErrorSend(error.response.data.message);
          setLoadValider(false);
        });
    }
  };
  const onCancel = () => {
    if (props.type === 'add') {
      navigate(-1);
    } else {
      props.handleClose();
    }
  };
  const getIndex = (fieldName) => {
    for (let i = 0; i < dateValue.length; i++) {
      if (dateValue[i].name === fieldName) {
        return dateValue[i].index;
      }
    }
  };
  const formatDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy');
  };

  return load ? (
    <AgentLoader />
  ) : errorChargement !== null ? (
    <ErrorPage message={errorChargement} />
  ) : (
    <Box margin={1}>
      <Typography variant="h3" align="center" marginBottom={2}>
        {papier.nom}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={'column'} spacing={2}>
          {props.type === 'add' && (
            <Stack direction={'row'} spacing={2}>
              <TextField
                name="examplaire"
                label="Nombre"
                type="number"
                value={examplaire}
                InputProps={{
                  inputProps: { min: 1 },
                }}
                onChange={(event) => {
                  setExamplaire(event.target.value);
                  setPrixLabel(fNumber(event.target.value * defaultPrix));
                }}
              />
              <TextField
                name="prix"
                label="Montant"
                type="text"
                variant="filled"
                InputProps={{
                  readOnly: true,
                  endAdornment: <InputAdornment position="end">Ar</InputAdornment>,
                }}
                value={prixLabel}
                // value={() => { return fNumber(prix) }}
              />
            </Stack>
          )}

          {data.map((champ, index) =>
            champ.type === 'date' ? (
              <LocalizationProvider key={index} dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                <DatePicker
                  value={dateValue[getIndex(champ.nom)].value}
                  name={champ.nom}
                  onChange={(newDate) => handleDateChange(getIndex(champ.nom), newDate)}
                  renderInput={(params) => <TextField {...params} />}
                  label={champ.nom}
                />
              </LocalizationProvider>
            ) : (
              <TextField
                key={index}
                type={champ.type}
                defaultValue={champ.value}
                {...register(champ.nom)}
                label={champ.nom}
              />
            )
          )}
          {/* erreur d'envoie */}
          {errorSend !== null && (
            <Alert variant="filled" severity="error">
              Une erreur s'est produite
              <br />
              {errorSend}
            </Alert>
          )}
          <Grid container mt={2}>
            <Grid item lg={6} xs={6}>
              <center>
                <LoadingButton
                  onClick={onCancel}
                  variant="contained"
                  size="large"
                  endIcon={<ExitToApp />}
                  color="error"
                  loading={loadValider}
                  loadingIndicator="RETOUR"
                >
                  ANNULER
                </LoadingButton>
              </center>
            </Grid>
            <Grid item lg={6} xs={6}>
              <center>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={loadValider}
                  loadingPosition="end"
                  endIcon={<Check />}
                >
                  VALIDER
                </LoadingButton>
                {/* <input type="submit" value="ajout" /> */}
              </center>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </Box>
  );
};

export default FormulaireDemande;
