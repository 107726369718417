import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useNavigate } from 'react-router-dom';
import { getPapierElement } from 'src/service/PapierAdminService';
import { siteUrl } from 'src/utils/baseUrl';

import '/node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'src/assets/css/editor.css';
import AgentLoader, { AgentLoaderBackDrop } from '../AgentLoader';

const FormAjoutPapier = (props) => {
  const navigate = useNavigate();
  const [method, setMethod] = useState(props.method);
  const [values, setValues] = useState({
    id: props.data.id,
    idCommune: props.data.idCommune,
    nom: props.data.nom,
    tarifEspece: props.data.tarifEspece,
    compta: props.data.compta,
    unite: props.data.unite,
    autreUnite: props.data.autreUnite,
    agent: '',
    description: props.data.description,
    idTypePapier: props.data.idTypePapier,
  });

  const [isLoading, setLoading] = useState(true);

  const [valueTypePapier, setValuesTypePapier] = useState([]);
  const [valueUnite, setValueUnite] = useState([]);
  useEffect(() => {
    getPapierElement().then((response) => {
      setValuesTypePapier(response.data.items.type);
      setValueUnite(response.data.items.unite);
      setLoading(false);
    });
  }, []);
  const [response, setResponse] = useState(null);
  // const url = 'http://localhost:8080/v1/communes/1/papiers';
  // const url = urlPapierAdmin();
  let url = siteUrl('papiers');

  const agents = [
    { value: 1, label: 'Mario Marc' },
    { value: 2, label: 'Andry Soa' },
    { value: 3, label: 'Faly Manana' },
  ];

  const [editor, setEditor] = useState(() => {
    if (props.data.description === '') {
      return EditorState.createEmpty();
    } else {
      return EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.data.description)));
    }
  });
  const onEditorStateChange = (editorState) => {
    setEditor(editorState);
    values.description = draftToHtml(convertToRaw(editor.getCurrentContent()));
  };

  const createPapierAdmin = async () => {
    setStateBackDrop(true);
    try {
      const res = await axios.post(url, null, {
        params: {
          ...values,
        },
      });
      setResponse(res);

      if (res.status === 200) {
        navigate('/agent/papier-admins');
        console.log(res.data);
        setStateBackDrop(false);
        const lastPapier = {
          idPapier: res.data.items.id,
          nom: res.data.items.nom,
        };
        navigate('/agent/ajout-papier/cree-form', { state: { lastPapier } });
      }
      console.log(values);
    } catch (error) {
      setStateBackDrop(false);
      console.error(error);
    }
  };

  // const createPapierAdmin = () => {
  //   try {
  //     const res = createPapier(values);
  //     console.log(res);
  //     if (res.status === 200) {
  //       navigate('/agent/papier-admins');
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }

  // };

  const updatePapierAdmin = async () => {
    try {
      const res = await axios.put(url, null, {
        params: {
          ...values,
        },
      });
      setResponse(res);
      console.log(response);
      if (res.status === 200) {
        navigate('/agent/papier-admins');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (method === 'add') {
      createPapierAdmin();
    } else {
      updatePapierAdmin();
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  /*backdrop*/
  const [stateBackDrop, setStateBackDrop] = useState(false);
  if (isLoading) {
    return <AgentLoader />;
  } else {
    return (
      <>
        <AgentLoaderBackDrop open={stateBackDrop} />
        <Paper elevation={1}>
          <Typography variant="h3">{props.titre}</Typography>
          <Box m={2} p={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} rowSpacing={2}>
                <Grid item lg={6} xs={12}>
                  <TextField
                    id="nom"
                    name="nom"
                    label="Désignation"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    value={values.nom}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    id="idTypePapier"
                    name="idTypePapier"
                    value={values.idTypePapier}
                    label="Type de papier"
                    onChange={onChange}
                    fullWidth
                    select
                  >
                    {valueTypePapier.map((type, i) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.nom}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={6} xs={12}>
                  <TextField
                    id="tarifEspece"
                    type="number"
                    name="tarifEspece"
                    label="Tarif"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    value={values.tarifEspece}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    id="compta"
                    name="compta"
                    type="number"
                    label="Compta"
                    variant="outlined"
                    fullWidth
                    onChange={onChange}
                    value={values.compta}
                  />
                </Grid>

                <Grid item lg={6} xs={12}>
                  <FormLabel id="demo-row-radio-buttons-group-label">Unité</FormLabel>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    {valueUnite.map((item) => (
                      <FormControlLabel
                        key={item.id}
                        value={item.nom}
                        name="unite"
                        onChange={onChange}
                        control={<Radio checked={item.nom === values.unite ? true : false} />}
                        label={item.nom}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    id="autreUnite"
                    name="unite"
                    onChange={onChange}
                    label="autre unité"
                    variant="outlined"
                    value={values.unite !== '' ? values.unite : ''}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <Editor
                    editorState={editor}
                    wrapperClassName="wrapperClassName"
                    editorClassName="editor-class"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                      options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                      inline: { inDropdown: false },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                      },
                      list: { inDropdown: false },
                      textAlign: { inDropdown: false },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                    }}
                  />
                  <TextField
                    onChange={onChange}
                    hidden
                    name="description"
                    value={draftToHtml(convertToRaw(editor.getCurrentContent()))}
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item lg={6} xs={6}>
                  <center>
                    <Button
                      onClick={() => {
                        navigate(-1);
                      }}
                      variant="contained"
                      size="large"
                      color="inherit"
                    >
                      RETOUR
                    </Button>
                  </center>
                </Grid>
                <Grid item lg={6} xs={6}>
                  <center>
                    <Button type="submit" variant="contained" size="large">
                      {method === 'add' ? 'SUIVANT' : 'MODIFIER'}
                    </Button>
                    {/* <input type="submit" value="ajout" /> */}
                  </center>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </>
    );
  }
};

export default FormAjoutPapier;
