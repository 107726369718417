import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";


const axiosInstance = axios.create({
    baseURL: siteUrl("agents")
});

export function login(data) {
    return axiosInstance.request({
        method: "GET",
        url: "/login" ,
        params: data
    });
}

export const deleteProfil = async (idProfil) => {

    try {
        return await axios.put(siteUrl("agents/profiles/delete"), null, {
            params: {
                idProfil: idProfil
            }
        });
    } catch (error) {
        console.log(error);
    }

}

export const enableProfil = async (idProfil) => {

    try {
        return await axios.put(siteUrl("agents/profiles/enable"), null, {
            params: {
                idProfil: idProfil
            }
        });
    } catch (error) {
        console.log(error);
    }

}

export const disableProfil = async (idProfil) => {

    try {
        return await axios.put(siteUrl("agents/profiles/disable"), null, {
            params: {
                idProfil: idProfil
            }
        });
    } catch (error) {
        console.log(error);
    }

}

export const changerNiveau = async (idProfil, niveau) => {

    try {
        return await axios.put(siteUrl("agents/profiles/niveau"), null, {
            params: {
                idProfil: idProfil,
                niveau: niveau
            }
        });
    } catch (error) {
        console.log(error);
    }

}

export function getNiveau(niveau) {
    if (niveau === 0) return 'Simple utilisateur';
    if (niveau === 1) return 'Modérateur';
    if (niveau === 2) return 'Administrateur';
}

export function getProfileByIdProfile(idProfile) {
    return axiosInstance.request({
        method: "GET",
        url: "/profiles/" + idProfile
    });
}

export const newCompteAgent = async (profileAgent) => {

    const customConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    try {
        return await axios.post(siteUrl("agents/profiles"), JSON.stringify(profileAgent), customConfig);
    } catch (error) {
        console.log(error);
    }

}

export function getAgents() {
    return axiosInstance.request({
        method: "GET"
    });
}

export function getFonctions() {
    return axiosInstance.request({
        method: "GET",
        url: "/fonctions"
    });
}

export function getProfilesAgent() {
    return axiosInstance.request({
        method: "GET",
        url: "/profiles"
    });
}

export function getSansCompte() {
    return axiosInstance.request({
        method: "GET",
        url: "/sans-compte"
    });
}