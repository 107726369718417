import CreateForm from "./CreateForm";

const Test = () => {
    return (
        <CreateForm />
    );

};

export default Test;
