import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import FormAjoutCitoyen from './FormAjoutCitoyen';
import { fDateFr } from 'src/utils/formatTime';

export const ModalDetailCitoyen = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'À propos'}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Grid container>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>ID: </strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.id}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Nom: </strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.nom}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Prénom: </strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.prenom}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                {props.data.sexe === 'm' ? <strong>Né le:</strong> : <strong>Née le:</strong>}
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {(props.data.dateNaissance && fDateFr(props.data.dateNaissance)) + ' ' + `(${props.data.age} ans)`}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>À:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.lieuNaissance}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Sexe:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.sexe === 'm' ? 'homme' : 'femme'}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Fokontany:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.fokontany}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Adresse:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.adresse}
              </Grid>
            </Grid>
            <Grid container>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>CIN:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.cin}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Fait le:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.dateCin && fDateFr(props.data.dateCin)}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>À:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.lieuCin}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Profession:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.metier}
              </Grid>
            </Grid>
            <Grid container>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Père:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.pere}
              </Grid>
              <Grid Item xs={3} md={3} lg={2}>
                <strong>Mère:</strong>
              </Grid>
              <Grid Item xs={9} md={9} lg={10}>
                {props.data.mere}
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} autoFocus>
            FERMER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ModalDeleteCitoyen = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirmation de supression'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Êtes-vous sur de supprimer John ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="error">
            SUPPRIMER
          </Button>
          <Button onClick={props.handleClose} autoFocus>
            ANNULER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ModalModificationCitoyen = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Modification'}</DialogTitle>
        <DialogContent>
          <FormAjoutCitoyen data={props.data} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
