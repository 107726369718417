import React from 'react';
import Page from 'src/components/Page';
import { Grid, Typography, Box, Paper } from '@mui/material';

export default function DetailCitoyen() {
  return (
    <Page>
      {' '}
      <Box m={2} p={2}>
        <Paper elevation={2}>
          <Box p={2}>
            <Typography variant="h2" align="center">
              John Does
            </Typography>
            <Grid container rowSpacing={1}>
              <Grid item xs={12} md={2} />
              <Grid container={'true'} item xs={12} md={8}>
                {/* <Grid container rowSpacing={1}> */}
                <Grid item xs={12} md={4}>
                  <strong>Nom:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  Rakotobe
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Prénom:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  John Doe
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Date de naissance:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  13/ 10/ 2000
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Sexe:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  Homme
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Fokontany:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  Antanetibe
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Adresse:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  lot aa/10/tt
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>CIN:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  103100353131
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Fait le:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  10/12/2000
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>à:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  Ambohitratrimo
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Père:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  John Rakoto
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Mère:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  Rasoa Hery
                </Grid>
                <Grid item xs={12} md={4}>
                  <strong>Profession:</strong>
                </Grid>
                <Grid item xs={12} md={8}>
                  Chaffeur
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Grid item xs={12} md={2} />
            </Grid>
          </Box>
        </Paper>
      </Box>{' '}
    </Page>
  );
}
