import { Backdrop, Box, CircularProgress } from '@mui/material';

export const AgentLoaderMini = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <CircularProgress />
    </Box>
  );
};

const AgentLoader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
      <CircularProgress size={150} />
    </Box>
  );
};
export default AgentLoader;

export const AgentLoaderBackDrop = (props) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.open}>
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress size={150} />
      </Box>
    </Backdrop>
  );
};
