import { Box, Button, Divider, List, ListItem, ListItemText, ListSubheader, Typography } from "@mui/material";
import { AgentLoaderMini } from "../AgentLoader";
import { useState } from "react";
import { useEffect } from "react";
import { get, set } from "lodash";
import { getPapierAutorised } from "src/service/PapierAdminService";
import { ModeEdit } from "@mui/icons-material";

const PapierAutorised = (props) => {
    const [data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        getPapierAutorised(props.id).then(response => {
            setData(response.data.items);
            setLoading(false);

        }).catch(error => {
            console.error(error);
            setError(error.response.data.message);
        })
    }, []);
    return (
        <>
            {isLoading ? (<AgentLoaderMini />) :
                (
                    <Box margin={2}>
                        <Typography variant="h5">
                            Les papiers administratifs autrorisés
                        </Typography>
                        <Divider sx={{ borderColor: 'black', mb: 1 }} />
                        {data.length === 0 ? (
                            <Typography variant="body1" fontWeight={3}>
                                Aucun traitement de papier n'est autorisé à ce profil.
                            </Typography>) :
                            (
                                <List
                                    sx={{
                                        width: '100%',
                                        bgcolor: 'background.paper',
                                        position: 'relative',
                                        overflow: 'auto',
                                        maxHeight: 340,
                                        '& ul': { padding: 0 },
                                    }}
                                    subheader={<li />}
                                >
                                    {data.map((types) => (
                                        <li key={`type-${types.idType}`}>
                                            <ul>
                                                <ListSubheader sx={{ color: 'black' }}>{`${types.type}`}</ListSubheader>
                                                {types.papiers.map((papier) => (
                                                    <ListItem key={papier.id} >
                                                        <ListItemText sx={{ my: 0, py: 0 }}  >
                                                            <Typography sx={{ display: 'inline-block', mr: 1, ml: 2 }}>•</Typography>
                                                            <Typography variant="body1" component="span">{papier.nom}</Typography>
                                                        </ListItemText>
                                                    </ListItem>
                                                ))}
                                            </ul>
                                        </li>
                                    ))}
                                </List>
                            )
                        }

                        <Divider sx={{ borderColor: 'black', my: 1 }} />
                        <Box sx={{ textAlign: 'right' }}>
                            <Button variant="outlined" endIcon={<ModeEdit />}>MODIFIER</Button>
                        </Box>

                    </Box>
                )}
        </>


    );
}

export default PapierAutorised;