import { Button } from "@mui/material";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DetailAgent from "./DetailAgent";

const ModalDetailAgent = (props) => {
    return (
        <>
            <Modal
                centered
                isOpen={props.open}
                toggle={props.handleClose}
                className="modal-dialog-scrollable" >
                <ModalHeader toggle={props.handleClose}></ModalHeader>
                <ModalBody>
                    <DetailAgent data={props.data} />
                </ModalBody>
                <ModalFooter>
                    <Button variant="outlined" onClick={props.handleClose} >Fermer</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default ModalDetailAgent;