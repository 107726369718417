import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";
const axiosInstance = axios.create({
    baseURL: siteUrl("fokontany")
});

export function getFokontany() {
    return axiosInstance.request({
        method: "GET"
    });
}