import Label from "../Label";

const AgentStatus = ({ active }) => {
    return (
        <>
            {active === 1 ? (
                <Label variant="ghost" color="success" >
                    ACTIVE
                </Label >) :

                (<Label variant="ghost" color="error" >
                    INACTIVE
                </Label >)
            }
        </>
    );
}

export default AgentStatus;