import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import 'dayjs/locale/fr';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getFonctions } from 'src/service/AgentService';
import { siteUrl } from 'src/utils/baseUrl';

/*type default create , else modif*/
const ModalHandleAgent = (props) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [fonctions, setFonctions] = useState([]);
  const [values, setValues] = useState(props.data);
  /*date reference for date picker*/
  const [birthRef, setBirthRef] = useState(props.data.dateNaissance);
  const [dateCinRef, setDateCinRef] = useState(props.data.dateCin);

  /*image handling*/
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImage(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async () => {
    // e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);

    try {
      const response = await fetch(siteUrl('files/upload/agent'), {
        method: 'POST',
        body: formData,
      });
      return await response.json();
    } catch (error) {
      console.error(error);
    }
  };
  /*
    changing data
    changing image
    */
  useEffect(() => {
    if (props.modalType === 'edit') {
      console.log('miova edit');
      setValues(props.data);
      if (props.data.photo !== null) {
        setImagePreviewUrl(siteUrl('files/agent/' + props.data.photo));
      }
    }
    if (props.modalType === 'create') {
      console.log('miova create');
      if (props.initData.photo) {
        delete props.initData.photo;
        delete values.photo;
      }
      setImage(null);
      setImagePreviewUrl(null);
      setValues(props.initData);
    }
  }, [props.modalType, props.data, props.initData]);

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getFonctions().then((response) => {
      setFonctions(response.data.items);
      setLoading(false);
    });
  }, []);

  /*Traitement*/
  const newAgent = async () => {
    if (image !== null) {
      values.photo = image.name;
    }
    const customConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      return await axios.post(siteUrl('agents'), JSON.stringify(values), customConfig);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    /*sans upload image*/
    if (image === null) {
      await newAgent().then((responseAgent) => {
        if (responseAgent.status === 200) {
          // navigate('/agent/liste?new');
          // window.location = '/agent/liste?new';
          window.location.reload();
        }
      });
    } else {
      /*avec upload image*/
      await handleImageUpload().then(async (responseUpload) => {
        if (responseUpload.status === 200) {
          await newAgent().then((responseAgent) => {
            if (responseAgent.status === 200) {
              window.location.reload();
            }
          });
        }
      });
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <Modal centered isOpen={props.open} toggle={props.handleClose} className="modal-dialog-scrollable">
        <ModalHeader toggle={props.handleClose}></ModalHeader>
        <ModalBody>
          <>
            <Stack rowGap={2}>
              <Grid container alignItems="center" justifyItems="center" spacing={2} columnSpacing={2}>
                <Grid item lg={6} md={6} xs={12}>
                  {imagePreviewUrl ? (
                    <Avatar sx={{ width: 150, height: 150 }} src={imagePreviewUrl} />
                  ) : (
                    <Avatar sx={{ width: 150, height: 150 }} />
                  )}
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Stack spacing={1}>
                    <Button variant="contained" component="label">
                      {!imagePreviewUrl ? 'IMPORTER UNE PHOTO' : 'CHANGER'}
                      <input type="file" hidden onChange={handleImageChange} />
                    </Button>
                    {imagePreviewUrl && (
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setImage(null);
                          setImagePreviewUrl(null);
                        }}
                      >
                        ANNULER
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              <Grid container justifyItems="center" alignItems="center" spacing={2} columnSpacing={2}>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    required
                    label="Nom"
                    id="nom"
                    name="nom"
                    value={values.nom}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    required
                    label="Prénom"
                    id="prenom"
                    name="prenom"
                    value={values.prenom}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="text"
                    required
                    label="Fonction"
                    id="fonction"
                    name="fonction"
                    value={values.fonction}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                    select
                  >
                    {fonctions.map((fonction) => (
                      <MenuItem key={fonction.id} value={fonction.nom}>
                        {fonction.nom}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    type="text"
                    required
                    label="Autre Fonction"
                    id="fonction"
                    name="fonction"
                    value={values.fonction}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <FormLabel id="demo-row-radio-buttons-group-label">Sexe</FormLabel>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                    <FormControlLabel
                      key="homme"
                      value={'m'}
                      name="sexe"
                      onChange={onChange}
                      control={<Radio checked={'m' === values.sexe ? true : false} />}
                      label={'Homme'}
                    />
                    <FormControlLabel
                      key="femme"
                      value={'f'}
                      name="sexe"
                      onChange={onChange}
                      control={<Radio checked={'f' === values.sexe ? true : false} />}
                      label={'Femme'}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                    <DatePicker
                      value={birthRef}
                      onChange={(newValue) => {
                        setBirthRef(newValue);
                        values.dateNaissance = newValue;
                        console.log(values);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      label="Date de naissance"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    label="Lieu de naissance"
                    id="lieuNaissance"
                    name="lieuNaissance"
                    value={values.lieuNaissance}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    required
                    label="Adresse"
                    id="adresse"
                    name="adresse"
                    value={values.adresse}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    label="Numero CIN"
                    id="cin"
                    name="cin"
                    value={values.cin}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                    <DatePicker
                      value={dateCinRef}
                      onChange={(newValue) => {
                        setDateCinRef(newValue);
                        values.dateCin = newValue;
                        console.log(values);
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                      label="Date du CIN"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    label="Lieux CIN"
                    id="lieuCin"
                    name="lieuCin"
                    value={values.lieuCin}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    label="Téléphone"
                    id="telephone"
                    name="telephone"
                    value={values.telephone}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="text"
                    label="Email"
                    id="email"
                    name="email"
                    value={values.email}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    type="number"
                    label="Salaire"
                    id="salaire"
                    name="salaire"
                    value={values.salaire}
                    fullWidth
                    variant="outlined"
                    onChange={onChange}
                  />
                </Grid>
              </Grid>
            </Stack>
          </>
        </ModalBody>
        <ModalFooter>
          <Button variant="outlined" onClick={handleSubmit}>
            Valider
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              // initialisation();
              props.handleClose();
            }}
          >
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalHandleAgent;
