import { Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import ListePapierAdmin from 'src/components/papier/ListePapierAdmin';
import { getPapiers } from 'src/service/PapierAdminService';

const titre = 'Liste des papiers Administratifs';
// const rows = [
//   { id: 1, nom: 'papier1', unite: 'copie', tarifEspece: 20000, compta: '5420' },
//   { id: 2, nom: 'papier3', unite: 'm2', tarifEspece: 30000, compta: '5420' },
//   { id: 3, nom: 'papier3', unite: 'copie', tarifEspece: 30000, compta: '5420' },
// ];

const PapierAdmin = () => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getPapiers().then((response) => {
      setRows(response.data.items);
    });
  }, []);

  // useEffect(() => {
  //   fetch(`http://localhost:8080/v1/communes/1/papiers`)
  //     .then((response) => response.json())
  //     .then((actualData) => setRows(actualData.items));
  // }, []);
  return (
    <Page title="Liste papier">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Button
          variant="contained"
          component={RouterLink}
          to="/agent/ajout-papier"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Nouveau Papier
        </Button>
      </Stack>
      <ListePapierAdmin titre={titre} rows={rows} rank={false} />
    </Page>
  );
};

export default PapierAdmin;
