import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Chat from 'src/components/demande/Chat';
import DetailDemandeBox from 'src/components/demande/DetailDemandeBox';
import FichierListe from 'src/components/demande/FichierListe';
import ValueFormDemande from 'src/components/demande/ValueFormDemande';

const DetailDemandeAdmin = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const handleOpenSuccess = (newMessage) => {
    setMessageSuccess(newMessage);
    setOpenSuccessAlert(true);
  };
  // const handleClose = () => setOpen(false);
  const { id } = useParams();

  return (
    <>
      {/* <Typography variant="h3" align="center">Titre Demande</Typography> */}
      {/* <ModalRdv data={data} open={open} handleClose={handleClose} /> */}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardBody>
              <DetailDemandeBox idDemande={id} handleOpenFinish={handleOpen} />
            </CardBody>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <Box padding={2}>
              <ValueFormDemande handleOpenSuccess={(message) => handleOpenSuccess(message)} />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardBody>
              <FichierListe idDemande={id} />
            </CardBody>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardBody>
              <Chat idDemande={id} owner="agent" />
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailDemandeAdmin;
