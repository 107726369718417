import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}
export function fDateFr(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}
export function fDateFr2(date) {
  console.log("data en entree", date);
  if(typeof date === "undefined") return "";
  if(date === null )  return "" ;
  return format(new Date(date), 'dd/MM/yyyy');
}
export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeJava(date) {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
}

export function fDateSql(date) {
  console.log("data en entree", date)
  if(date === null){
    return null;
  }
  return format(new Date(date), 'yyyy-MM-dd');
}
export function fDateSql2(date) {
  console.log("data en entree", date)
  if(typeof date === "undefined") return "";
  if(date === null){
    return "";
  }
  return format(new Date(date), 'yyyy-MM-dd');
}

export function justTime(date) {
  return format(new Date(date), 'HH:mm');
}

export function fDateTimeFr(date) {
  return format(new Date(date), 'dd/MM/yyyy à HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
