import { Link } from 'react-router-dom';
import { Delete, Edit, MoreHoriz } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const ActionButton = (props) => {

  return (
    <>
      <IconButton
        onClick={() => {
          props.handleData();
          props.handleOpen();
        }}
      >
        <MoreHoriz />
      </IconButton>
      <IconButton component={Link} to="/agent/modif-papier" state={{ data: props.data }}>
        <Edit />
      </IconButton>
      <IconButton>
        <Delete />
      </IconButton>
    </>
  );
};

export default ActionButton;
