import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from 'src/components/Page';
import FormPapierAdmin from 'src/components/papier/FormPapierAdmin';

export default function ModifPapier() {
  const location = useLocation();
  const data = location.state?.data;
  return (
    <Page title="Modification Papier">
      <FormPapierAdmin data={data} titre={'Modification'} method={'update'} />
    </Page>
  );
}
