import { Delete, Edit, MoreVert, ToggleOff, ToggleOn, Visibility } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AgentStatus from 'src/components/agent/AgentStatus';
import ModalDeleteCompte from 'src/components/agent/ModalDeleteCompte';
import ModalDetailCompte from 'src/components/agent/ModalDetailCompte';
import ModalDisableCompte from 'src/components/agent/ModalDisableCompte';
import ModalEditNiveau from 'src/components/agent/ModalEditNiveau';
import ModalEnableCompte from 'src/components/agent/ModaleEnableCompte';
import ModalNewCompteAgent from 'src/components/agent/ModalNewCompteAgent';
import AgentLoader from 'src/components/AgentLoader';
import Page from 'src/components/Page';
import { getProfilesAgent } from 'src/service/AgentService';
import { siteUrl } from 'src/utils/baseUrl';

const MenuProfile = (props) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const alignStart = { display: 'flex', justifyContent: 'flex-start' };

  const clickDetail = () => {
    props.handleOpenDetail();
    setIsOpen(false);
  };

  const clickEdit = () => {
    props.handleOpenEdit();
    setIsOpen(false);
  };

  const clickDisable = () => {
    props.handleOpenDisable();
    setIsOpen(false);
  };

  const clickEnable = () => {
    props.handleOpenEnable();
    setIsOpen(false);
  };
  const clickDelete = () => {
    props.handleOpenDelete();
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVert />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem component={Button} onClick={clickDetail} fullWidth sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Visibility />
          </ListItemIcon>
          <ListItemText primary="Détail" style={alignStart} />
        </MenuItem>
        <MenuItem component={Button} onClick={clickEdit} sx={{ color: 'text.secondary' }} fullWidth>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Modifier" style={alignStart} />
        </MenuItem>
        {props.data.active > 0 ? (
          <MenuItem component={Button} onClick={clickDisable} sx={{ color: 'text.secondary' }} fullWidth>
            <ListItemIcon>
              <ToggleOff />
            </ListItemIcon>
            <ListItemText primary="Désactiver" style={alignStart} />
          </MenuItem>
        ) : (
          <MenuItem component={Button} onClick={clickEnable} sx={{ color: 'text.secondary' }} fullWidth>
            <ListItemIcon>
              <ToggleOn />
            </ListItemIcon>
            <ListItemText primary="Activer" style={alignStart} />
          </MenuItem>
        )}

        <MenuItem component={Button} onClick={clickDelete} sx={{ color: 'text.secondary' }} fullWidth>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Supprimer" style={alignStart} />
        </MenuItem>
      </Menu>
    </>
  );
};

const CompteAgent = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setLoading] = useState(true);

  /*modal Ajout*/
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /*modal par ligne*/
  const [openDetail, setOpenDetail] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDisable, setDisable] = useState(false);
  const [openEnable, setEnable] = useState(false);
  const [openDelete, setDelete] = useState(false);

  const [data, setData] = useState({ id: null, niveau: null });

  const handleOpenDetail = (data) => {
    setData(data);
    setOpenDetail(true);
  };
  const handleOpenEdit = (data) => {
    setData(data);
    setOpenEdit(true);
  };
  const handleOpenDisable = (data) => {
    setData(data);
    setDisable(true);
  };
  const handleOpenEnable = (data) => {
    setData(data);
    setEnable(true);
  };

  const handleOpenDelete = (data) => {
    setData(data);
    setDelete(true);
  };

  useEffect(() => {
    getProfilesAgent().then((response) => {
      setRows(response.data.items);
      setLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <>
        <AgentLoader />
      </>
    );
  }
  return (
    <Page title="Compte Agent">
      <Container>
        <ModalNewCompteAgent open={open} handleClose={handleClose} />
        <ModalDetailCompte Compte open={openDetail} id={data.id} handleClose={() => setOpenDetail(false)} />
        <ModalEditNiveau open={openEdit} data={data} handleClose={() => setOpenEdit(false)} />
        <ModalDisableCompte open={openDisable} data={data} handleClose={() => setDisable(false)} />
        <ModalEnableCompte open={openEnable} data={data} handleClose={() => setEnable(false)} />
        <ModalDeleteCompte open={openDelete} data={data} handleClose={() => setDelete(false)} />

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3">Comptes des agents</Typography>
          <Button onClick={handleOpen}> Nouveau compte</Button>
        </Stack>
        <Card>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left">Nom</TableCell>
                  <TableCell align="left">Fonction</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.idAgent}>
                    <TableCell align="right">
                      {row.photo !== null ? <Avatar src={siteUrl('files/agent/' + row.photo)} /> : <Avatar />}
                    </TableCell>
                    <TableCell align="left">{row.nom + ' ' + row.prenom}</TableCell>
                    <TableCell align="left">{row.fonction}</TableCell>
                    <TableCell align="left">
                      <AgentStatus active={row.active} />
                    </TableCell>
                    <TableCell>
                      <MenuProfile
                        data={row}
                        handleOpenDetail={() => handleOpenDetail(row)}
                        handleOpenEdit={() => handleOpenEdit(row)}
                        handleOpenDisable={() => handleOpenDisable(row)}
                        handleOpenEnable={() => handleOpenEnable(row)}
                        handleOpenDelete={() => handleOpenDelete(row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
};

export default CompteAgent;
