import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}

RHFTextFieldDate.propTypes={
  name: PropTypes.string.isRequired
}
export function RHFTextFieldDate({name, ...other}){
  const { control} = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
              {...field}
              label="Date de naissance"
              renderInput={(params) => <TextField 
                fullWidth 
                {...params} 
                error={!!error} 
                helperText={error?.message} 
                {...other}/>}
        />
      )}
    />
    </LocalizationProvider>
  );
}