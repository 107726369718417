import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import * as Yup from 'yup';

import { Box, Button, Grid, MenuItem, Paper } from '@mui/material';
import { ajoutCitoyen } from 'src/service/CitoyenService';
import MessageClose from '../feedback/Notification';
import { RHFRadioButton } from '../hook-form/RHFRadioButton';
import { RHFTextFieldDate } from '../hook-form/RHFTextField';
import AgentLoader from '../AgentLoader';
import { getFokontany } from 'src/service/FokontanyService';

const FormAjoutCitoyen = ({ data }) => {
  // const fokontanys = [
  //   { value: 1, label: 'Antanetibe' },
  //   { value: 2, label: 'Mahazaza' },
  //   { value: 3, label: 'Andranofotsy' },
  // ];
  const sexe = [
    { value: 'm', label: 'Homme' },
    { value: 'f', label: 'Femme' },
  ];
  const resetValues = {
    nom: '',
    prenom: '',
    idFokontany: 1,
    adresse: '',
    dateNaissance: '',
    lieuNaissance: '',
    sexe: 'm',
    pere: '',
    mere: '',
    cin: '',
    dateCin: '',
    lieuCin: '',
    metier: '',
  };
  const resetForm = () => {};
  const [fokontanys, setFokontany] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [defaultValues, setDefaultValue] = useState(data);

  useEffect(() => {
    getFokontany().then((response) => {
      setFokontany(response.data.items);
      if (data) {
        setDefaultValue(data);
      }
      setLoading(false);
    });
  }, []);

  /* form config */
  const formSchema = Yup.object().shape({
    nom: Yup.string().required('Veullez saisir le nom'),
  });

  /*feedback*/
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  /*on submit*/
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data) => {
    console.log('submited');
    ajoutCitoyen(data)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        console.log(error.response.status);
        console.log(error.response.data.message);
        setError(error.response.data.message);
      });
  };
  if (isLoading) {
    return <AgentLoader />;
  } else {
    return (
      <Paper elevation={1}>
        <Box m={2} p={3}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item lg={12} xs={12}>
                <RHFTextField name="nom" required label="Nom" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={12} xs={12}>
                <RHFTextField name="prenom" label="Prénom(s)" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={6} xs={12}>
                <RHFTextFieldDate name="dateNaissance" label="Date de naissance" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={6} xs={12}>
                <RHFTextField name="lieuNaissance" label="Lieu de naissance" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={12} xs={12}>
                <RHFRadioButton name="sexe" options={sexe} direction="row" />
              </Grid>
              <Grid item lg={4} xs={12}>
                <RHFTextField name="idFokontany" select label="Fokontany" variant="outlined" fullWidth>
                  {fokontanys.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {' '}
                      {item.nom}
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Grid>
              <Grid item lg={8} xs={12}>
                <RHFTextField name="adresse" label="Adresse" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={4} xs={12}>
                <RHFTextField name="cin" label="Numero CIN" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={4} xs={12}>
                <RHFTextFieldDate name="dateCin" label="Date du CIN" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={4} xs={12}>
                <RHFTextField name="lieuCin" label="Lieux du CIN" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={12} xs={12}>
                <RHFTextField name="pere" label="Père" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={12} xs={12}>
                <RHFTextField name="mere" label="Mère" variant="outlined" fullWidth />
              </Grid>
              <Grid item lg={12} xs={12}>
                <RHFTextField name="metier" label="Métier" variant="outlined" fullWidth />
              </Grid>
            </Grid>
            <Grid container mt={2}>
              <Grid item lg={6} xs={6}>
                <center>
                  <Button variant="contained" color="inherit" size="large">
                    ANNULER
                  </Button>
                </center>
              </Grid>
              <Grid item lg={6} xs={6}>
                <center>
                  <Button variant="contained" type="submit" size="large">
                    AJOUTER
                  </Button>
                </center>
              </Grid>
            </Grid>
          </FormProvider>
          <Box mt={2}>
            {success !== null && (
              <MessageClose message={'Enregistrement réussi'} severity="success" variant="outlined" />
            )}
            {error !== null && <MessageClose message="Une erreur s'est produite" severity="error" variant="outlined" />}
          </Box>
        </Box>
      </Paper>
    );
  }
};

export default FormAjoutCitoyen;
