import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AgentLoader from 'src/components/AgentLoader';
import Page from 'src/components/Page';
import { AppConversionRates } from 'src/sections/@dashboard/app';
import { ChartLabelsString } from 'src/sections/@dashboard/app/AppWebsiteVisits';
import { getStatPopulation } from 'src/service/CitoyenService';
import { fDate, fDateFr, fDateFr2 } from 'src/utils/formatTime';

const GraphPop = () => {
  const location = useLocation();
  const { myData } = location.state || {};
  const [isLoading, setLoading] = useState(true);
  const [dataFokontany, setDataFokontany] = useState([]);
  const [chartLabelAge, setChartLabelAge] = useState([]);
  const [chartSerieAge, setChartSerieAge] = useState([]);
  useEffect(() => {
    getStatPopulation(myData)
      .then((response) => {
        setDataFokontany(response.data.fokotanyStat);
        setChartLabelAge(response.data.ageStat.trancheLabel);
        setChartSerieAge(response.data.ageStat.ageSeries);
        setLoading(false);
      })
      .then((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <AgentLoader />;
  } else {
    return (
      <>
        <Page title="graph">
          <Container>
            <Typography variant="h1">Graphique</Typography>
            <Grid container spacing={1}>
              {/*nbr population par fokontany*/}
              <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                  title="Population par fokontany"
                  // subheader="(+43%) than last year"
                  chartData={dataFokontany}
                />
              </Grid>
              {/*critere*/}
              <Grid item lg={4} xs={12}>
                <Card elevation={2}>
                  <Box p={1}>
                    <Typography variant="h3">Critères:</Typography>
                    <Grid container>
                      <Grid item lg={3} xs={3}>
                        <strong>Nom:</strong>
                      </Grid>
                      <Grid item lg={9} xs={9}>
                        {myData.nom}
                      </Grid>
                      {/* <Grid item lg={3} xs={3}>
                        <strong>Fokontany:</strong>
                      </Grid>
                      <Grid item lg={9} xs={9}></Grid> */}
                      <Grid item lg={3} xs={3}>
                        <strong>Adresse:</strong>
                      </Grid>
                      <Grid item lg={9} xs={9}>
                        {myData.adresse}
                      </Grid>
                      <Grid item lg={3} xs={3}>
                        <strong>Naissance:</strong>
                      </Grid>
                      <Grid item lg={9} xs={9}>
                        {fDateFr2(myData.dateMin)} {' - '}
                        {fDateFr2(myData.dateMax)}
                      </Grid>
                      <Grid item lg={3} xs={3}>
                        <strong>Âge:</strong>
                      </Grid>
                      <Grid item lg={9} xs={9}>
                        {myData.ageMin} - {myData.ageMax}
                      </Grid>
                      <Grid item lg={3} xs={3}>
                        <strong>Sexe:</strong>
                      </Grid>
                      <Grid item lg={9} xs={9}>
                        {myData.sexe === 'm' && 'Homme'}
                        {myData.sexe === 'f' && 'Femme'}
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <ChartLabelsString
                  title="Nombre par tranche d'âge"
                  chartLabels={chartLabelAge}
                  chartData={[
                    {
                      name: 'nombre',
                      type: 'column',
                      fill: 'solid',
                      data: chartSerieAge,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </>
    );
  }
};

export default GraphPop;
