import { createContext, useContext } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

    const getAgent = () => {
        return JSON.parse(localStorage.getItem('agent'));
    }

    const loginAgent = (agent) => {
        localStorage.setItem('agent', JSON.stringify(agent));
    };
    const logoutAgent = () => {
        localStorage.removeItem('agent');
    };

    const getCitoyen = () => {
        return JSON.parse(localStorage.getItem('citoyen'));
    }
    const loginCitoyen = (citoyen) => {
        const data = { idCitoyen: citoyen.id, pseudo: citoyen.pseudo, active: citoyen.active };
        localStorage.setItem('citoyen', JSON.stringify(data));
    };

    const logoutCitoyen = () => {
        localStorage.removeItem('citoyen');
    };

    return (
        <AuthContext.Provider value={{ loginAgent, logoutAgent, loginCitoyen, logoutCitoyen, getAgent, getCitoyen }}>
            {children}
        </AuthContext.Provider>
    );


}

export const useAuth = () => {
    return useContext(AuthContext)
}
