import axios from "axios";
import { siteUrl } from "src/utils/baseUrl";

const axiosInstance = axios.create({
    baseURL: siteUrl("files")
});

export const deletePieceJointe = async (idPieceJointe) => {
    try {
        return await axios.delete(siteUrl("files/" + idPieceJointe));
    } catch (error) {
        console.error(error);
    }
}

export const downloadFile = async (idPiece, fileName) => {
    try {
        // return await axiosInstance.request({
        //     method: "GET",
        //     url: "/download/" + idPiece
        // });
        await axiosInstance.request({
            method: "GET",
            url: "/download/" + idPiece,
            responseType: 'blob',
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        // .then(response => { console.log(response) });
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getPieceJointByIdDemande = async (idDemande) => {
    try {
        return await axiosInstance.request({
            method: "GET",
            url: "/" + idDemande
        });
    } catch (error) {
        console.error(error);
        return null;
    }
}


const url = siteUrl('files/')
export async function uploadFiles(idDemandeValue, files) {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
    }
    try {
        return await axios.post((url + 'uploads'), formData, {
            "content-type": "multipart/form-data",
            params: { idDemande: idDemandeValue }
        });
    } catch (error) {
        console.error("Failed to submit files.");
    }
}

export async function getImageAgent(imageName) {
    try {
        const response = await fetch(siteUrl("files/agent/" + imageName));
        const data = await response.blob();
        return URL.createObjectURL(data);
    } catch (error) {
        console.error(error);
    }
}