import { Grid } from '@mui/material';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { formatMoney } from 'src/utils/formatNumber';
import parse from 'html-react-parser'


const ModalDetailPapier = (props) => {
    return (
        <div>

            <Modal
                centered
                isOpen={props.open}
                toggle={props.handleClose} >
                <ModalHeader toggle={props.handleClose}>{props.data.nom}</ModalHeader>
                <ModalBody>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <strong>Type:</strong>
                        </Grid>
                        <Grid item xs={9}>
                            {props.data.typePapier}
                        </Grid>
                        <Grid item xs={3}>
                            <strong>Unité:</strong>
                        </Grid>
                        <Grid item xs={9}>
                            {props.data.unite}
                        </Grid>
                        <Grid item xs={3}>
                            <strong>Tarifs:</strong>
                        </Grid>
                        <Grid item xs={9}>
                            {formatMoney(props.data.tarifAutre)}
                        </Grid>
                        <Grid item xs={3}>
                            <strong> Compta:</strong>
                        </Grid>
                        <Grid item xs={9}>
                            {props.data.compta}
                        </Grid>
                        <Grid item xs={3}>
                            <strong>Description:</strong>
                        </Grid>
                        <Grid item xs={9}>
                            {props.data.description != null && parse((props.data.description))}
                        </Grid>
                    </Grid>
                </ModalBody>
                {/* <ModalFooter>
                    <Button color="primary" onClick={props.handleClose}>Do Something</Button>{' '}
                    <Button color="secondary" onClick={props.handleClose}>Cancel</Button>
                </ModalFooter> */}
            </Modal>
        </div>);
}

export default ModalDetailPapier;