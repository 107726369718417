// import env from "react-dotenv";
// const domaine = "localhost:8080";
// const domaine = "192.168.118.43:8080";
const domaine = "65.108.84.251:8080";


export function getBaseUrl() {
    return "http://" + domaine + "/v1/communes/1/";
}
export function siteUrl(site) {
    return "http://" + domaine + "/v1/communes/1/" + site;
}