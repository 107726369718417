import { Close, InsertDriveFile, Upload } from "@mui/icons-material";
import { Alert, AlertTitle, Avatar, Box, Button, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFileUpload from "react-use-file-upload";
import { uploadFiles } from "src/service/FileService";


const UploadForm = (props) => {
    const [isUpload, setUpload] = useState(false);
    const idDemande = props.idDemande;
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    /*handling upload form*/
    const {
        files,
        fileNames,
        clearAllFiles,
        setFiles,
        removeFile
    } = useFileUpload();

    const inputRef = useRef();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setUpload(true);
        // try {
        await uploadFiles(idDemande, files).then(
            response => {
                setUpload(false);
                if (response.status === 200) {
                    if (props.handleClose !== null) {
                        props.handleClose();
                        props.refresh();
                    } else {
                        navigate(props.nextUrl);
                    }

                } else {
                    setError(response.data.message);
                    console.log(error);
                }
            }
        );
        // } catch (error) {
        //     setUpload(false);
        //     setError("Impossible d'uploader le(s) fichier(s)");
        // }

    };
    return (
        <>
            {error !== null && (
                <Alert variant="filled" severity="error">
                    <AlertTitle>Erreur</AlertTitle>
                    {error}
                </Alert>
            )}


            <div className="App">
                <div css={CSS}>

                    <div>

                        <Button fullWidth color="info" variant="contained" onClick={() => inputRef.current.click()}>
                            Choisir le(s) fichier(s) {<Upload />}
                        </Button>
                        <div>
                            <List>
                                {fileNames.map((name, i) => (

                                    <ListItem key={i} secondaryAction={
                                        <IconButton onClick={() => removeFile(name)} edge="end" aria-label="delete">
                                            <Close />
                                        </IconButton>}>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <InsertDriveFile />
                                            </Avatar>
                                        </ListItemAvatar>

                                        <ListItemText sx={{ wordWrap: 'break-word' }}
                                            primary={name}
                                        />


                                    </ListItem>


                                ))}
                            </List>
                            {
                                isUpload === true &&
                                (
                                    <Box sx={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                )
                            }

                            {files.length > 0 && (
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => clearAllFiles()}
                                        disabled={isUpload === true && true}
                                    >Tout effacer
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                        disabled={isUpload === true && true}
                                    >Valider
                                    </Button>
                                </Box>
                            )}

                        </div>

                        <div>

                            {/* Hide the crappy looking default HTML input */}
                            <input
                                ref={inputRef}
                                type="file"
                                multiple
                                name="files"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    setFiles(e, "a");
                                    inputRef.current.value = null;
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}
export default UploadForm;
