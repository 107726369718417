import Page from 'src/components/Page';
import FormPapierAdmin from 'src/components/papier/FormPapierAdmin';

export default function AjoutPapier() {
  const data = {
    id: '',
    idCommune: 1,
    idTypePapier: 1,
    nom: '',
    tarifEspece: '',
    compta: '',
    unite: 'pièce',
    autreUnite: '',
    agent: '',
    description: '',
  };


  return (
    <Page title="Nouveau Papier">
      <FormPapierAdmin data={data} titre={'Nouveau Papier Administratif'} method={'add'} />
    </Page>
  );
}
